import Api from '@/services/Api'

export default {
    planCuentPorCompaVig(idComp,vig){
        return Api().get('planCuentPorCompaVig/'+ idComp + '/' + vig)
    },
    planCuentPorId(id){
        return Api().get('planCuentPorId/'+ id)
    },
    planCuentasUpdate(form){
        return Api().put('planCuentas/update', form)
    },
    getVigenciaPlancuentas(){
        return Api().get('planCuentas/getVigenciaPlancue')
    },
    planCuentasMassiveUpdate(form){
        return Api().put('planCuentas/massiveUpdate', form)
    }
}