<template>
    <div class="container">
        <b-row>
            <b-container>
                <h3 class="text-center py-2" v-if="!generado">
                    <b><i>{{f_titulo()}}</i></b>
		</h3>
                <h5 class="text-center py-2">
			<select v-model="dialogFechas" @click="isEmpty=true;">
			<option
				v-for="(item, idx) in fechas"
				:key="'fecha-'+idx"
				:value="item.fecha">
				{{item.fecha}}
			</option>
			</select> &nbsp;&nbsp;
                    <select v-model="dialogCompanias" v-on:click="seleccionaCompania()" >
                      <option
                        v-for="(item, idx) in companias"
                        :key="'compania-'+idx"
                        :value="item.id">
                        {{item.acronym+" "+item.nombre}}
                      </option>
                    </select> &nbsp;&nbsp;
                    <label for="Balance">Balance</label>
			&nbsp;
            <input type="checkbox" id="Balance" value="Balance" v-on:click="uncheck(1)" checked> &nbsp; &nbsp;
            <label for="Comparativo">Comparativo</label> &nbsp;
            <input type="checkbox" id="Comparativo" value="Comparativo" v-on:click="uncheck(3)"> &nbsp;&nbsp;		
            <label for="Consolidado">Consolidado</label> &nbsp;
            <input type="checkbox" id="Consolidado" value="Consolidado" v-on:click="uncheck(2)"> &nbsp;&nbsp;		
            <b-button variant="outline-primary" @click="generar">
		<h4><b><i>{{labels.generar}}</i></b></h4>
            </b-button>

	</h5>
    </b-container>
</b-row>
<b-row>
    <b-container v-if="!isEmpty && Balance" class="pb-5">
	<md-table v-model="balance" md-card md-fixed-header @md-selected="f_muestra">
		<md-table-toolbar>
			<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
			<md-button v-if="fgl_excel" @click="excel(1)" class = "md-fab md-primary "><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>
		</h1>
		</md-table-toolbar>
		<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
			<md-table-cell :md-label="labels.descripcion" class="text-left border">{{item.descripcion}}</md-table-cell>
			<md-table-cell :md-label="labels.saldo_actual" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.saldo_actual) }}</md-table-cell>
			<md-table-cell :md-label="labels.saldo_actual" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.funcional1" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.funcional1) }}</md-table-cell>
			<md-table-cell :md-label="labels.funcional1" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.promedio1" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.promedio1) }}</md-table-cell>
			<md-table-cell :md-label="labels.promedio1" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
<!--			<md-table-cell :md-label="labels.interna1" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.interna1) }}</md-table-cell>
			<md-table-cell :md-label="labels.interna1" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
-->
			<md-table-cell :md-label="labels.funcional2" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.funcional2) }}</md-table-cell>
			<md-table-cell :md-label="labels.funcional2" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.promedio2" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.promedio2) }}</md-table-cell>
			<md-table-cell :md-label="labels.promedio2" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
<!--			<md-table-cell :md-label="labels.interna2" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.interna2) }}</md-table-cell>
			<md-table-cell :md-label="labels.interna2" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
-->
		</md-table-row>
	</md-table>
    </b-container>
    <b-container v-if="!isEmpty && Consolidado" class="pb-5">
	<md-table v-model="balance" md-card md-fixed-header>
		<md-table-toolbar>
			<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(3)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>

			</h1>
		</md-table-toolbar>
		<md-table-row slot="md-table-row" slot-scope="{ item }">
			<md-table-cell :md-label="labels.descripcion" class="text-left border">{{ item.descripcion }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[0]" class="text-right border" v-if="columnas>=1 && f_escribe_renglon(item.informacion)">{{ formato(item.c1) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[0]" class="text-right border" v-if="columnas>=1 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[1]" class="text-right border" v-if="columnas>=2 && f_escribe_renglon(item.informacion)">{{ formato(item.c2) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[1]" class="text-right border" v-if="columnas>=2 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[2]" class="text-right border" v-if="columnas>=3 && f_escribe_renglon(item.informacion)">{{ formato(item.c3) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[2]" class="text-right border" v-if="columnas>=3 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[3]" class="text-right border" v-if="columnas>=4 && f_escribe_renglon(item.informacion)">{{ formato(item.c4) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[3]" class="text-right border" v-if="columnas>=4 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[4]" class="text-right border" v-if="columnas>=5 && f_escribe_renglon(item.informacion)">{{ formato(item.c5) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[4]" class="text-right border" v-if="columnas>=5 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[5]" class="text-right border" v-if="columnas>=6 && f_escribe_renglon(item.informacion)">{{ formato(item.c6) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[5]" class="text-right border" v-if="columnas>=6 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[6]" class="text-right border" v-if="columnas>=7 && f_escribe_renglon(item.informacion)">{{ formato(item.c7) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[6]" class="text-right border" v-if="columnas>=7 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[7]" class="text-right border" v-if="columnas>=8 && f_escribe_renglon(item.informacion)">{{ formato(item.c8) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[7]" class="text-right border" v-if="columnas>=8 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[8]" class="text-right border" v-if="columnas>=9 && f_escribe_renglon(item.informacion)">{{ formato(item.c9) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[8]" class="text-right border" v-if="columnas>=9 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[9]" class="text-right border" v-if="columnas>=10 && f_escribe_renglon(item.informacion)">{{ formato(item.c10) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[9]" class="text-right border" v-if="columnas>=10 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[10]" class="text-right border" v-if="columnas>=11 && f_escribe_renglon(item.informacion)">{{ formato(item.c11) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[10]" class="text-right border" v-if="columnas>=11&& !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[11]" class="text-right border" v-if="columnas>=12 && f_escribe_renglon(item.informacion)">{{ formato(item.c12) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[11]" class="text-right border" v-if="columnas>=12 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[12]" class="text-right border" v-if="columnas>=13 && f_escribe_renglon(item.informacion)">{{ formato(item.c13) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[12]" class="text-right border" v-if="columnas>=13 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[13]" class="text-right border" v-if="columnas>=14 && f_escribe_renglon(item.informacion)">{{ formato(item.c14) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[13]" class="text-right border" v-if="columnas>=14 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[14]" class="text-right border" v-if="columnas>=15 && f_escribe_renglon(item.informacion)">{{ formato(item.c15) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[14]" class="text-right border" v-if="columnas>=15 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[15]" class="text-right border" v-if="columnas>=16 && f_escribe_renglon(item.informacion)">{{ formato(item.c16) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[15]" class="text-right border" v-if="columnas>=16 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[16]" class="text-right border" v-if="columnas>=17 && f_escribe_renglon(item.informacion)">{{ formato(item.c17) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[16]" class="text-right border" v-if="columnas>=17 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[17]" class="text-right border" v-if="columnas>=18 && f_escribe_renglon(item.informacion)">{{ formato(item.c18) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[17]" class="text-right border" v-if="columnas>=18 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[18]" class="text-right border" v-if="columnas>=19 && f_escribe_renglon(item.informacion)">{{ formato(item.c19) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[18]" class="text-right border" v-if="columnas>=19 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[19]" class="text-right border" v-if="columnas>=20 && f_escribe_renglon(item.informacion)">{{ formato(item.c20) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[19]" class="text-right border" v-if="columnas>=20 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[20]" class="text-right border" v-if="columnas>=21 && f_escribe_renglon(item.informacion)">{{ formato(item.c21) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[20]" class="text-right border" v-if="columnas>=21 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[21]" class="text-right border" v-if="columnas>=22 && f_escribe_renglon(item.informacion)">{{ formato(item.c22) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[21]" class="text-right border" v-if="columnas>=22 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[22]" class="text-right border" v-if="columnas>=23 && f_escribe_renglon(item.informacion)">{{ formato(item.c23) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[22]" class="text-right border" v-if="columnas>=23 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[23]" class="text-right border" v-if="columnas>=24 && f_escribe_renglon(item.informacion)">{{ formato(item.c24) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[23]" class="text-right border" v-if="columnas>=24 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[24]" class="text-right border" v-if="columnas>=25 && f_escribe_renglon(item.informacion)">{{ formato(item.c25) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[24]" class="text-right border" v-if="columnas>=25 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[25]" class="text-right border" v-if="columnas>=26 && f_escribe_renglon(item.informacion)">{{ formato(item.c26) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[25]" class="text-right border" v-if="columnas>=26 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[26]" class="text-right border" v-if="columnas>=27 && f_escribe_renglon(item.informacion)">{{ formato(item.c27) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[26]" class="text-right border" v-if="columnas>=27 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[27]" class="text-right border" v-if="columnas>=28 && f_escribe_renglon(item.informacion)">{{ formato(item.c28) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[27]" class="text-right border" v-if="columnas>=28 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[28]" class="text-right border" v-if="columnas>=29 && f_escribe_renglon(item.informacion)">{{ formato(item.c29) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[28]" class="text-right border" v-if="columnas>=29 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[29]" class="text-right border" v-if="columnas>=30 && f_escribe_renglon(item.informacion)">{{ formato(item.c30) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[29]" class="text-right border" v-if="columnas>=30 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[30]" class="text-right border" v-if="columnas>=31 && f_escribe_renglon(item.informacion)">{{ formato(item.c31) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[30]" class="text-right border" v-if="columnas>=31 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[31]" class="text-right border" v-if="columnas>=32 && f_escribe_renglon(item.informacion)">{{ formato(item.c32) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[31]" class="text-right border" v-if="columnas>=32 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[32]" class="text-right border" v-if="columnas>=33 && f_escribe_renglon(item.informacion)">{{ formato(item.c33) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[32]" class="text-right border" v-if="columnas>=33 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[33]" class="text-right border" v-if="columnas>=34 && f_escribe_renglon(item.informacion)">{{ formato(item.c34) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[33]" class="text-right border" v-if="columnas>=34 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[34]" class="text-right border" v-if="columnas>=35 && f_escribe_renglon(item.informacion)">{{ formato(item.c35) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[34]" class="text-right border" v-if="columnas>=35 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[35]" class="text-right border" v-if="columnas>=36 && f_escribe_renglon(item.informacion)">{{ formato(item.c36) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[35]" class="text-right border" v-if="columnas>=36 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[36]" class="text-right border" v-if="columnas>=37 && f_escribe_renglon(item.informacion)">{{ formato(item.c37) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[36]" class="text-right border" v-if="columnas>=37 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[37]" class="text-right border" v-if="columnas>=38 && f_escribe_renglon(item.informacion)">{{ formato(item.c38) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[37]" class="text-right border" v-if="columnas>=38 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[38]" class="text-right border" v-if="columnas>=39 && f_escribe_renglon(item.informacion)">{{ formato(item.c39) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[38]" class="text-right border" v-if="columnas>=39 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[39]" class="text-right border" v-if="columnas>=40 && f_escribe_renglon(item.informacion)">{{ formato(item.c40) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[39]" class="text-right border" v-if="columnas>=40 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[40]" class="text-right border" v-if="columnas>=41 && f_escribe_renglon(item.informacion)">{{ formato(item.c41) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[40]" class="text-right border" v-if="columnas>=41 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[41]" class="text-right border" v-if="columnas>=42 && f_escribe_renglon(item.informacion)">{{ formato(item.c42) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[41]" class="text-right border" v-if="columnas>=42 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[42]" class="text-right border" v-if="columnas>=43 && f_escribe_renglon(item.informacion)">{{ formato(item.c43) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[42]" class="text-right border" v-if="columnas>=43 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[43]" class="text-right border" v-if="columnas>=44 && f_escribe_renglon(item.informacion)">{{ formato(item.c44) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[43]" class="text-right border" v-if="columnas>=44 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[44]" class="text-right border" v-if="columnas>=45 && f_escribe_renglon(item.informacion)">{{ formato(item.c45) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[44]" class="text-right border" v-if="columnas>=45 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[45]" class="text-right border" v-if="columnas>=46 && f_escribe_renglon(item.informacion)">{{ formato(item.c46) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[45]" class="text-right border" v-if="columnas>=46 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[46]" class="text-right border" v-if="columnas>=47 && f_escribe_renglon(item.informacion)">{{ formato(item.c47) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[46]" class="text-right border" v-if="columnas>=47 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[47]" class="text-right border" v-if="columnas>=48 && f_escribe_renglon(item.informacion)">{{ formato(item.c48) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[47]" class="text-right border" v-if="columnas>=48 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[48]" class="text-right border" v-if="columnas>=49 && f_escribe_renglon(item.informacion)">{{ formato(item.c49) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[48]" class="text-right border" v-if="columnas>=49 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[49]" class="text-right border" v-if="columnas>=50 && f_escribe_renglon(item.informacion)">{{ formato(item.c50) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[49]" class="text-right border" v-if="columnas>=50 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[50]" class="text-right border" v-if="columnas>=51 && f_escribe_renglon(item.informacion)">{{ formato(item.c51) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[50]" class="text-right border" v-if="columnas>=51 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[51]" class="text-right border" v-if="columnas>=52 && f_escribe_renglon(item.informacion)">{{ formato(item.c52) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[51]" class="text-right border" v-if="columnas>=52 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[52]" class="text-right border" v-if="columnas>=53 && f_escribe_renglon(item.informacion)">{{ formato(item.c53) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[52]" class="text-right border" v-if="columnas>=53 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[53]" class="text-right border" v-if="columnas>=54 && f_escribe_renglon(item.informacion)">{{ formato(item.c54) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[53]" class="text-right border" v-if="columnas>=54 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[54]" class="text-right border" v-if="columnas>=55 && f_escribe_renglon(item.informacion)">{{ formato(item.c55) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[54]" class="text-right border" v-if="columnas>=55 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[55]" class="text-right border" v-if="columnas>=56 && f_escribe_renglon(item.informacion)">{{ formato(item.c56) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[55]" class="text-right border" v-if="columnas>=56 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[56]" class="text-right border" v-if="columnas>=57 && f_escribe_renglon(item.informacion)">{{ formato(item.c57) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[56]" class="text-right border" v-if="columnas>=57 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[57]" class="text-right border" v-if="columnas>=58 && f_escribe_renglon(item.informacion)">{{ formato(item.c58) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[57]" class="text-right border" v-if="columnas>=58 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[58]" class="text-right border" v-if="columnas>=59 && f_escribe_renglon(item.informacion)">{{ formato(item.c59) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[58]" class="text-right border" v-if="columnas>=59 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[59]" class="text-right border" v-if="columnas>=59 && f_escribe_renglon(item.informacion)">{{ formato(item.c60) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[59]" class="text-right border" v-if="columnas>=60 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[60]" class="text-right border" v-if="columnas>=60 && f_escribe_renglon(item.informacion)">{{ formato(item.c61) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[60]" class="text-right border" v-if="columnas>=61 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[61]" class="text-right border" v-if="columnas>=61 && f_escribe_renglon(item.informacion)">{{ formato(item.c62) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[61]" class="text-right border" v-if="columnas>=62 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[62]" class="text-right border" v-if="columnas>=63 && f_escribe_renglon(item.informacion)">{{ formato(item.c63) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[62]" class="text-right border" v-if="columnas>=63 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[63]" class="text-right border" v-if="columnas>=64 && f_escribe_renglon(item.informacion)">{{ formato(item.c64) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[63]" class="text-right border" v-if="columnas>=64 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[64]" class="text-right border" v-if="columnas>=65 && f_escribe_renglon(item.informacion)">{{ formato(item.c65) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[64]" class="text-right border" v-if="columnas>=65 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[65]" class="text-right border" v-if="columnas>=66 && f_escribe_renglon(item.informacion)">{{ formato(item.c66) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[65]" class="text-right border" v-if="columnas>=66 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[66]" class="text-right border" v-if="columnas>=67 && f_escribe_renglon(item.informacion)">{{ formato(item.c67) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[66]" class="text-right border" v-if="columnas>=67 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[67]" class="text-right border" v-if="columnas>=68 && f_escribe_renglon(item.informacion)">{{ formato(item.c68) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[67]" class="text-right border" v-if="columnas>=68 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="labels.ajustes" class="text-right border" v-if="columnas>0 && f_escribe_renglon(item.informacion)">{{ formato(item.ajustes) }}</md-table-cell>
			<md-table-cell :md-label="labels.ajustes" class="text-right border" v-if="columnas>0 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="labels.consolidado" class="text-right border" v-if="columnas>0 && f_escribe_renglon(item.informacion)">{{ formato(item.total) }}</md-table-cell>
			<md-table-cell :md-label="labels.consolidado" class="text-right border" v-if="columnas>0 && !f_escribe_renglon(item.informacion)"></md-table-cell>
		</md-table-row>
	</md-table>
    </b-container>
</b-row>
<b-row>
    <b-container v-if="!isEmpty && Comparativo" class="pb-5">
	<md-table v-model="balance" md-card md-fixed-header>
		<md-table-toolbar>
			<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(2)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>

			</h1>
		</md-table-toolbar>
		<md-table-row slot="md-table-row" slot-scope="{ item }">
			<md-table-cell :md-label="labels.descripcion" class="text-left">{{item.descripcion}}</md-table-cell>
			<md-table-cell :md-label="titulo(0)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.anterior) }}</md-table-cell>
			<md-table-cell :md-label="titulo(0)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="titulo(1)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.actual) }}</md-table-cell>
			<md-table-cell :md-label="titulo(1)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.diferencia" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.diferencia) }}</md-table-cell>
			<md-table-cell :md-label="labels.diferencia" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="titulo(2)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.funcional_ant) }}</md-table-cell>
			<md-table-cell :md-label="titulo(2)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="titulo(3)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.funcional_act) }}</md-table-cell>
			<md-table-cell :md-label="titulo(3)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.funcional_dif" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.funcional_dif) }}</md-table-cell>
			<md-table-cell :md-label="labels.funcional_dif" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
<!--			<md-table-cell :md-label="titulo(4)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.alterna_ant) }}</md-table-cell>
			<md-table-cell :md-label="titulo(4)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="titulo(5)" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.alterna_act) }}</md-table-cell>
			<md-table-cell :md-label="titulo(5)" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
			<md-table-cell :md-label="labels.alterna_dif" class="text-right border" v-if="f_escribe_renglon1(item.informacion)">{{ formato(item.alterna_dif) }}</md-table-cell>
			<md-table-cell :md-label="labels.alterna_dif" v-if="!f_escribe_renglon1(item.informacion)" class="text-right border" ></md-table-cell>
-->
		</md-table-row>
                </md-table>
            </b-container>
            <b-container v-if="isEmpty">
                <b-col md="2" class="mx-auto">
                    <div class="success-svg mb-4" :class="{ active: isEmpty }">
                        <img src="../../assets/imgs/file-2.svg" alt="warning" />
                    </div>
                </b-col>
                <b-col md="12" class="justify-content-around">
                    <span id="message" v-if="isEmpty">{{labels.empty}}</span>
                </b-col>
            </b-container>
		<md-dialog :md-active.sync="showDialog">
			<md-dialog-title class="text-center"><h3><b><i>{{f1_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(4)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>

			</h3></md-dialog-title>
			<md-dialog-content>
				<md-table v-model="cuentas" md-card md-fixed-header>
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell :md-label="labels.cuenta" class="text-left border">{{item.cuenta}}</md-table-cell>
						<md-table-cell :md-label="labels.descripcion_cuenta" class="text-left border">{{item.descripcion}}</md-table-cell>
						<md-table-cell :md-label="labels.saldo_actual" class="text-right border" >{{ formato(item.saldo_actual) }}</md-table-cell>
						<md-table-cell :md-label="labels.funcional1" class="text-right border" >{{ formato(item.funcional1) }}</md-table-cell>
						<md-table-cell :md-label="labels.promedio1" class="text-right border" >{{ formato(item.promedio1) }}</md-table-cell>
						<md-table-cell :md-label="labels.funcional2" class="text-right border" >{{ formato(item.funcional2) }}</md-table-cell>
						<md-table-cell :md-label="labels.promedio2" class="text-right border" >{{ formato(item.promedio2) }}</md-table-cell>
					</md-table-row>
				</md-table>
				<md-dialog-actions>
					<md-button class="md-accent" @click="showDialog = false">Cerrar</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
        </b-row>

    </div>
</template>
<script>
import Balance from "@/services/BalanceNiif"
import * as XLSX from "exceljs"

const ordenefechas = (arreglo = undefined) => {
	return [...arreglo].sort((a,b)=>a-b)
}

export default {
  name: "BalanceNiif",

  data: () => ({
	rolSeleccionado: {},//<-- el seleccionado estará aquí
	myOptions: [],
	rolSelected: '',
	showDialog: false,
	formCuentas : {},
	dialogItem: {},
	balance: [],
	cuentas: [],
	searched: [],
	isEmpty: true,
	isLoading: false,
	error: false,
	idCompania:1,
	vigencia:'',
	Balance:true,
	Comparativo:false,
	Consolidado:false,
	companias : [],
	dialogCompanias : [],
	fechas : [],
	dialogFechas : [],
	columnas: 0,
	titulo_columna: [],
	generado: false,
	codigoCompanias: "",
	moneda: 0,
	t_saldo: 0,
	t_funcional:0,
	t_promedio:0,
	t_alterna:0,
	t_prom_alterna:0,
	rutaExcel: '',
	fgl_excel: true
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "BALANCE",
          codigo: "Codigo",
          descripcion: "Descripcion / Niif (IFRS)",
          cuenta: "Cuenta",
          descripcion_cuenta: "Descripcion",
          saldo_periodo_anterior: "Saldo Periodo Anterior",
          saldo_actual: "Saldo",
          funcional1: "Funcional",
          alterna1: "Alterna",
          promedio1: "Promedio",
          interna1: "Interna",
          funcional2: "Alterna",
          promedio2: "Promedio",
          interna2: "Interna",
          saldo_periodo_actual: "Saldo A",
          diferencia: "Diferencia",
          funcional_dif: "Dif Funcional",
          alterna_dif: "Dif Alterna",
          compania: "Compañia",
          empty: "Sin Seleccion",
          notFoundMessage: "Sin Informacion",
          generar : "Generar",
          ajustes : "Ajustes",
          consolidado : "Consolidado",
          moneda : "Moneda",
        }
      }else {
        return {
          title: "NIIF CHECKING BALANCE",
          codigo: "Code",
          cuenta: "Account",
          descripcion_cuenta: "Description",
          descripcion: "Description",
          funcional1: "Funcional",
          alterna1: "Alternate",
          promedio1: "Average",
          interna1: "Internal",
          funcional2: "Alternative",
          promedio2: "Average",
          interna2: "Internal",
          saldo_actual: "Currency Balance",
          saldo_periodo_anterior: "Previous Period Balance",
          saldo_periodo_actual: "Current Period Balance",
          diferencia: "Difference",
          funcional_dif: "Dif Funcional",
          alterna_dif: "Dif Alternate",
          monto: "Amount",
          compania: "Company",
          empty: "Without Information",
          notFoundMessage: "Without Information",
          generar : "Trigger",
          ajustes : "Settings",
          consolidado : "Consolidated",
          moneda : "Currency",
        }
      }
    }
  },
  methods: {
    seleccionaCompania() {
	this.isEmpty=true;
        let buscaMoneda = this.companias.filter((item) =>
                item.id==this.dialogCompanias)
	this.moneda = buscaMoneda[0].acronimo
    },
    f_titulo() {
	let titulo = ""
	let tituloEN = ""
	let tituloConsolidado = ""
	switch (this.$route.name) {
	case  "balanceNiif" :
		titulo = "BALANCE"
		tituloEN = "BALANCE"
		break;
	case  "reporteEsf" :
		titulo = "ESTADO DE LA SITUACION FINANCIERA-ESF"
		tituloEN = "FINANCIAL POSITION STATEMENT-ESF"
		break;
	case  "reporteEri" :
		titulo = "ESTADO DE RESULTADO INTEGRAL-ERI"
		tituloEN = "COMPREHENSIVE INCOME STATEMENT-ERI"
		break;
	case  "reporteEfe" :
		titulo = "ESTADO DEL FLUJO DE EFECTIVO-EFE"
		tituloEN = "CASH FLOW STATEMENT-EFE"
		break;
	case  "reporteEcp" :
		titulo = "ESTADO DE LA CONSOLIDACION PATRIMONIAL-ECP"
		tituloEN = "EQUITY CONSOLIDATION STATEMENT-ECP"
		break;
	}
	if	(this.$store.state._language == "es-ES") {
		tituloConsolidado = titulo
	} else	{
		tituloConsolidado = tituloEN
	}
	if	(this.Consolidado) {
		if	(this.$store.state._language == "es-ES") {
			if	(this.$route.name == "reporteEsf") {
				tituloConsolidado = titulo.concat(" CONSOLIDADA")
			} else {
				tituloConsolidado = titulo.concat(" CONSOLIDADO")
			}
		} else {
			tituloConsolidado = "CONSOLIDATED"
			tituloConsolidado = tituloConsolidado.concat(" ",tituloEN)
		}
	}
	if	(this.Comparativo) {
		if	(this.$store.state._language == "es-ES") {
			if	(this.$route.name == "reporteEsf") {
				tituloConsolidado = titulo.concat(" COMPARATIVO")
			} else {
				tituloConsolidado = titulo.concat(" COMPARATIVO")
			}
		} else {
			tituloConsolidado = "COMPARATIVE"
			tituloConsolidado = tituloConsolidado.concat(" ",tituloEN)
		}
	}
	tituloConsolidado=tituloConsolidado.concat(" ",
			this.vigencia.toUpperCase());
	if (this.moneda) {
		tituloConsolidado=tituloConsolidado.concat(" ",
			this.labels.moneda," ",this.moneda);
	}
	return tituloConsolidado
    },
    f1_titulo() {
	let titulo = this.formCuentas.descripcion
	return titulo
    },
    generar() {
	if	(this.dialogCompanias && this.dialogFechas) {
		this.idCompania=this.dialogCompanias
		this.vigencia=this.dialogFechas
		this.isEmpty=true
		this.generado=false
		switch (this.$route.name) {
		case  "balanceNiif" :
			if	(this.Balance) {
				Balance.getBalanceNiif(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.balanceNiif;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
					this.error = true;
				});
			}
			if	(this.Consolidado) {
				Balance.getBalanceNiifConsol(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.balanceNiif;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
						this.columnas = this.balance[0].columnas
						this.f_columnas()
					}
				})
				.catch(() => {
					this.error = true;
				});
			}
			if	(this.Comparativo) {
				Balance.getBalanceNiifCompar(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.balanceNiif;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
					this.error = true;
				});
			}
			break;
		case  "reporteEsf" :
			if	(this.Balance) {
				Balance.getReporteEsf(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.reporteEsf;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Consolidado) {
				Balance.getReporteEsfConsol(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.reporteEsf;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
						this.columnas = this.balance[0].columnas
						this.f_columnas()
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Comparativo) {
				Balance.getReporteEsfCompar(this.idCompania,this.vigencia)
				.then((result) => {
					this.balance = result.data.reporteEsf;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			break;
		case  "reporteEri" :
			if	(this.Balance) {
				Balance.getReporteEri(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEri;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Consolidado) {
				Balance.getReporteEriConsol(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEri;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
						this.columnas = this.balance[0].columnas
						this.f_columnas()
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Comparativo) {
				Balance.getReporteEriCompar(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEri;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			break;
		case  "reporteEcp" :
			if	(this.Balance) {
				Balance.getReporteEcp(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEcp;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Consolidado) {
				Balance.getReporteEcpConsol(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEcp;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
						this.columnas = this.balance[0].columnas
						this.f_columnas()
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			if	(this.Comparativo) {
				Balance.getReporteEcpCompar(this.idCompania,this.vigencia)
				.then((result) => {
				// console.log(result.data)
					this.balance = result.data.reporteEcp;
					this.searched = this.balance;
					if (this.balance.length != 0) {
						this.isEmpty = false;
						this.generado=true
						this.columnas = this.balance[0].columnas
						this.f_columnas()
					}
				})
				.catch(() => {
				// console.log(error);
					this.error = true;
				});
			}
			break;
		}
	}
    },
    f_columnas() {
	for (let idx=0;idx< this.columnas;idx++) {
		switch	(idx) {
		case 0: this.titulo_columna[idx]  = this.balance[0].e1; break;
		case 1: this.titulo_columna[idx]  = this.balance[0].e2; break;
		case 2: this.titulo_columna[idx]  = this.balance[0].e3; break;
		case 3: this.titulo_columna[idx]  = this.balance[0].e4; break;
		case 4: this.titulo_columna[idx]  = this.balance[0].e5; break;
		case 5: this.titulo_columna[idx]  = this.balance[0].e6; break;
		case 6: this.titulo_columna[idx]  = this.balance[0].e7; break;
		case 7: this.titulo_columna[idx]  = this.balance[0].e8; break;
		case 8: this.titulo_columna[idx]  = this.balance[0].e9; break;
		case 9: this.titulo_columna[idx]  = this.balance[0].e10; break;
		case 10: this.titulo_columna[idx]  = this.balance[0].e11; break;
		case 11: this.titulo_columna[idx]  = this.balance[0].e12; break;
		case 12: this.titulo_columna[idx]  = this.balance[0].e13; break;
		case 13: this.titulo_columna[idx]  = this.balance[0].e14; break;
		case 14: this.titulo_columna[idx]  = this.balance[0].e15; break;
		case 15: this.titulo_columna[idx]  = this.balance[0].e16; break;
		case 16: this.titulo_columna[idx]  = this.balance[0].e17; break;
		case 17: this.titulo_columna[idx]  = this.balance[0].e18; break;
		case 18: this.titulo_columna[idx]  = this.balance[0].e19; break;
		case 19: this.titulo_columna[idx]  = this.balance[0].e20; break;
		case 20: this.titulo_columna[idx]  = this.balance[0].e21; break;
		case 21: this.titulo_columna[idx]  = this.balance[0].e22; break;
		case 22: this.titulo_columna[idx]  = this.balance[0].e23; break;
		case 23: this.titulo_columna[idx]  = this.balance[0].e24; break;
		case 24: this.titulo_columna[idx]  = this.balance[0].e25; break;
		case 25: this.titulo_columna[idx]  = this.balance[0].e26; break;
		case 26: this.titulo_columna[idx]  = this.balance[0].e27; break;
		case 27: this.titulo_columna[idx]  = this.balance[0].e28; break;
		case 28: this.titulo_columna[idx]  = this.balance[0].e29; break;
		case 29: this.titulo_columna[idx]  = this.balance[0].e30; break;
		case 30: this.titulo_columna[idx]  = this.balance[0].e31; break;
		case 31: this.titulo_columna[idx]  = this.balance[0].e32; break;
		case 32: this.titulo_columna[idx]  = this.balance[0].e33; break;
		case 33: this.titulo_columna[idx]  = this.balance[0].e34; break;
		case 34: this.titulo_columna[idx]  = this.balance[0].e35; break;
		case 35: this.titulo_columna[idx]  = this.balance[0].e36; break;
		case 36: this.titulo_columna[idx]  = this.balance[0].e37; break;
		case 37: this.titulo_columna[idx]  = this.balance[0].e38; break;
		case 38: this.titulo_columna[idx]  = this.balance[0].e39; break;
		case 39: this.titulo_columna[idx]  = this.balance[0].e40; break;
		case 40: this.titulo_columna[idx]  = this.balance[0].e41; break;
		case 41: this.titulo_columna[idx]  = this.balance[0].e42; break;
		case 42: this.titulo_columna[idx]  = this.balance[0].e43; break;
		case 43: this.titulo_columna[idx]  = this.balance[0].e44; break;
		case 44: this.titulo_columna[idx]  = this.balance[0].e45; break;
		case 45: this.titulo_columna[idx]  = this.balance[0].e46; break;
		case 46: this.titulo_columna[idx]  = this.balance[0].e47; break;
		case 47: this.titulo_columna[idx]  = this.balance[0].e48; break;
		case 48: this.titulo_columna[idx]  = this.balance[0].e49; break;
		case 49: this.titulo_columna[idx]  = this.balance[0].e50; break;
		case 50: this.titulo_columna[idx]  = this.balance[0].e51; break;
		case 51: this.titulo_columna[idx]  = this.balance[0].e52; break;
		case 52: this.titulo_columna[idx]  = this.balance[0].e53; break;
		case 53: this.titulo_columna[idx]  = this.balance[0].e54; break;
		case 54: this.titulo_columna[idx]  = this.balance[0].e55; break;
		case 55: this.titulo_columna[idx]  = this.balance[0].e56; break;
		case 56: this.titulo_columna[idx]  = this.balance[0].e57; break;
		case 57: this.titulo_columna[idx]  = this.balance[0].e58; break;
		case 58: this.titulo_columna[idx]  = this.balance[0].e59; break;
		case 59: this.titulo_columna[idx]  = this.balance[0].e60; break;
		case 60: this.titulo_columna[idx]  = this.balance[0].e61; break;
		case 61: this.titulo_columna[idx]  = this.balance[0].e62; break;
		case 62: this.titulo_columna[idx]  = this.balance[0].e63; break;
		case 63: this.titulo_columna[idx]  = this.balance[0].e64; break;
		case 64: this.titulo_columna[idx]  = this.balance[0].e65; break;
		case 65: this.titulo_columna[idx]  = this.balance[0].e66; break;
		case 66: this.titulo_columna[idx]  = this.balance[0].e67; break;
		case 67: this.titulo_columna[idx]  = this.balance[0].e68; break;
		case 68: this.titulo_columna[idx]  = this.balance[0].e69; break;
		}
	}
    },
    titulo(opcion) {
	let periodo_anterior = this.vigencia.substring(0,4)-1;
	let v_periodo_anterior = ""
	v_periodo_anterior = v_periodo_anterior.concat(periodo_anterior,"12")
	if	(opcion == 0) {
		return this.labels.saldo_actual+" "+v_periodo_anterior;
	}
	if	(opcion == 1) {
		return this.labels.saldo_actual+" "+this.vigencia;
	}
	if	(opcion == 2) {
		return this.labels.funcional1+" "+v_periodo_anterior;
	}
	if	(opcion == 3) {
		return this.labels.funcional1+" "+this.vigencia;
	}
	if	(opcion == 4) {
		return this.labels.alterna1+" "+v_periodo_anterior;
	}
	if	(opcion == 5) {
		return this.labels.alterna1+" "+this.vigencia;
	}
    },
    f_escribe_renglon(v_informacion) {
	if	(v_informacion == "E") return false;
	return true;
    },
    f_escribe_renglon1(v_informacion) {
	if	(v_informacion == "E") return false;
	return true;
    },
    uncheck(opcion) {
      var checkbox1 = document.getElementById("Balance");
      var checkbox2 = document.getElementById("Consolidado");
      var checkbox3 = document.getElementById("Comparativo");
      checkbox1.checked=false;
      checkbox2.checked=false;
      checkbox3.checked=false;
      this.Balance=false;
      this.Comparativo=false;
      this.Consolidado=false;
      if (opcion == 1) {
          checkbox1.checked=true;
          this.Balance=true;
      }
      if (opcion == 2) {
          checkbox2.checked=true;
          this.Consolidado=true;
      }
      if (opcion == 3) {
          checkbox3.checked=true;
          this.Comparativo=true;
      }
      this.isEmpty=true;
      this.generado=false;
    },
	excel(opcion) {
		let datos = {}
		if	(opcion === 1) {
			datos.opcion = 1
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.informacion = this.balance
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelBalance(datos)
		}
		if	(opcion === 2) {
			datos.opcion = 2
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.informacion = this.balance
			datos.columnas = [
				this.titulo(0),
				this.titulo(1),
				this.labels.diferencia,
				this.titulo(2),
				this.titulo(3),
				this.labels.funcional_dif
			]
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelComparativo(datos)
		}
		if	(opcion === 3) {
			datos.opcion = 3
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.informacion = this.balance
			datos.columnas = []
			let i = 0
			for	(i=0;i<this.columnas;i++) {
				datos.columnas[i] = this.titulo_columna[i]
			}
			datos.columnas[i]= "Ajustes"
			i=i+1
			datos.columnas[i]= "Consolidado"

			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelConsolidado(datos)
		}
		if	(opcion === 4) {
			datos.opcion = 4
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f1_titulo()
			datos.informacion = this.cuentas
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelCuentas(datos)
		}
	},

	async hojaExcelBalance (datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet("Balance")

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			'DESCRIPCION/NIIF (IFRS)',
			'SALDO',
			'FUNCIONAL',
			'PROMEDIO',
			'ALTERNA',
			'PROMEDIO',
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<=6;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<6;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		filas.forEach((fila) => {
			if	(fila.informacion === "E") {
				const v_salto = [ "" ]
				worksheet.addRow(v_salto)
				n_fila++
			}
			const v_fila = [
				fila.descripcion,
				(fila.saldo_actual === null) ? fila.saldo_actual : parseFloat(fila.saldo_actual),
				(fila.funcional1 === null) ? fila.funcional1 : parseFloat(fila.funcional1),
				(fila.promedio1 === null) ? fila.promedio1 : parseFloat(fila.promedio1),
				(fila.funcional2 === null) ? fila.funcional2 : parseFloat(fila.funcional2),
				(fila.promedio2 === null) ? fila.promedio2 : parseFloat(fila.promedio2),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<6;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna === 0) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
						italic: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
					}
					worksheet.getCell(v_columna).alignment = {
						horizontal : (fila.informacion === "I" || (fila.informacion === 'T' && fila.segundo_nivel > 0 && fila.segundo_nivel<999) || (fila.informacion = 'E' && fila.segundo_nivel>0 && fila.segundo_nivel < 999)) ? 'right' : 'left',
					}
				} else {
					worksheet.getCell(v_columna).font = {
						size: 13,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})

		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		downloadLink.download = "Balance"+vigencia+".xlsx"
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},
	async hojaExcelComparativo(datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let columnas = datos.columnas
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet("Comparativo")

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			'DESCRIPCION/NIIF (IFRS)',
			columnas[0],
			columnas[1],
			columnas[2],
			columnas[3],
			columnas[4],
			columnas[5],
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<7;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		filas.forEach((fila) => {
			if	(fila.informacion === "E") {
				const v_salto = [ "" ]
				worksheet.addRow(v_salto)
				n_fila++
			}
			const v_fila = [
				fila.descripcion,
				(fila.anterior === null) ? fila.anterior : parseFloat(fila.anterior),
				(fila.actual === null) ? fila.actual : parseFloat(fila.actual),
				(fila.diferencia === null) ? fila.diferencia : parseFloat(fila.diferencia),
				(fila.funcional_ant === null) ? fila.funcional_ant : parseFloat(fila.funcional_ant),
				(fila.funcional_act === null) ? fila.funcional_act : parseFloat(fila.funcional_act),
				(fila.funcional_dif === null) ? fila.funcional_dif : parseFloat(fila.funcional_dif),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<7;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna === 0) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
						italic: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
					}
					worksheet.getCell(v_columna).alignment = {
						horizontal : (fila.informacion === "I" || (fila.informacion === 'T' && fila.segundo_nivel > 0 && fila.segundo_nivel<999) || (fila.informacion = 'E' && fila.segundo_nivel>0 && fila.segundo_nivel < 999)) ? 'right' : 'left',
					}
				} else {
					worksheet.getCell(v_columna).font = {
						size: 13,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})

		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		downloadLink.download = "Comparativo"+vigencia+".xlsx"
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},
	async hojaExcelConsolidado(datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let columnas = datos.columnas
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet("Consolidado")

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			'DESCRIPCION/NIIF (IFRS)',
		]
		for     (let k=0;k<filas[0].columnas+2;k++) {
			const v_celda = columnas[k]
			HEADER_ROW.push(v_celda)
		}
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<filas[0].columnas+2;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		filas.forEach((fila) => {
			const v_informacion = fila.informacion
			if	(fila.informacion === "E") {
				const v_salto = [ "" ]
				worksheet.addRow(v_salto)
				n_fila++
			}
			const v_fila = [
				fila.descripcion,
			]
			for     (let k=0;k<filas[0].columnas;k++) {
				const v_celda = this.f_val_fila(v_informacion,fila,k)
				v_fila.push(v_celda)
			}
			const v_ajustes = this.f_valor(v_informacion,fila.ajustes)
			v_fila.push(v_ajustes)
			const v_total = this.f_valor(v_informacion,fila.total)
			v_fila.push(v_total)

			worksheet.addRow(v_fila)
			for	(let columna=0;columna<=filas[0].columnas+2;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna === 0) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
						italic: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
					}
					worksheet.getCell(v_columna).alignment = {
						horizontal : (fila.informacion === "I" || (fila.informacion === 'T' && fila.segundo_nivel > 0 && fila.segundo_nivel<999) || (fila.informacion = 'E' && fila.segundo_nivel>0 && fila.segundo_nivel < 999)) ? 'right' : 'left',
					}
				} else {
					worksheet.getCell(v_columna).font = {
						size: 13,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
		})

		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		downloadLink.download = "Consolidado"+vigencia+".xlsx"
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},

	f_val_fila (p_informacion,fila,k) {
		let v_valor = ''
		switch (k) {
			case	0	: v_valor = fila.c1;break;
			case	1	: v_valor = fila.c2;break;
			case	2	: v_valor = fila.c3;break;
			case	3	: v_valor = fila.c4;break;
			case	4	: v_valor = fila.c5;break;
			case	5	: v_valor = fila.c6;break;
			case	6	: v_valor = fila.c7;break;
			case	7	: v_valor = fila.c8;break;
			case	8	: v_valor = fila.c9;break;
			case	9	: v_valor = fila.c10;break;
			case	10	: v_valor = fila.c11;break;
			case	11	: v_valor = fila.c12;break;
			case	12	: v_valor = fila.c13;break;
			case	13	: v_valor = fila.c14;break;
			case	14	: v_valor = fila.c15;break;
			case	15	: v_valor = fila.c16;break;
			case	16	: v_valor = fila.c17;break;
			case	17	: v_valor = fila.c18;break;
			case	18	: v_valor = fila.c19;break;
			case	19	: v_valor = fila.c20;break;
			case	20	: v_valor = fila.c21;break;
			case	21	: v_valor = fila.c22;break;
			case	22	: v_valor = fila.c23;break;
			case	23	: v_valor = fila.c24;break;
			case	24	: v_valor = fila.c25;break;
			case	25	: v_valor = fila.c26;break;
			case	26	: v_valor = fila.c27;break;
			case	27	: v_valor = fila.c28;break;
			case	28	: v_valor = fila.c29;break;
			case	29	: v_valor = fila.c30;break;
			case	30	: v_valor = fila.c31;break;
			case	31	: v_valor = fila.c32;break;
			case	32	: v_valor = fila.c33;break;
			case	33	: v_valor = fila.c34;break;
			case	34	: v_valor = fila.c35;break;
			case	35	: v_valor = fila.c36;break;
			case	36	: v_valor = fila.c37;break;
			case	37	: v_valor = fila.c38;break;
			case	38	: v_valor = fila.c39;break;
			case	39	: v_valor = fila.c40;break;
			case	40	: v_valor = fila.c41;break;
			case	41	: v_valor = fila.c42;break;
			case	42	: v_valor = fila.c43;break;
			case	43	: v_valor = fila.c44;break;
			case	44	: v_valor = fila.c45;break;
			case	45	: v_valor = fila.c46;break;
			case	46	: v_valor = fila.c47;break;
			case	47	: v_valor = fila.c48;break;
			case	48	: v_valor = fila.c49;break;
			case	49	: v_valor = fila.c50;break;
			case	50	: v_valor = fila.c51;break;
			case	51	: v_valor = fila.c52;break;
			case	52	: v_valor = fila.c53;break;
			case	53	: v_valor = fila.c54;break;
			case	54	: v_valor = fila.c55;break;
			case	55	: v_valor = fila.c56;break;
			case	56	: v_valor = fila.c57;break;
			case	57	: v_valor = fila.c58;break;
			case	58	: v_valor = fila.c59;break;
			case	59	: v_valor = fila.c60;break;
			case	70	: v_valor = fila.c61;break;
			case	61	: v_valor = fila.c62;break;
			case	62	: v_valor = fila.c63;break;
			case	63	: v_valor = fila.c64;break;
			case	64	: v_valor = fila.c65;break;
			case	65	: v_valor = fila.c66;break;
			case	66	: v_valor = fila.c67;break;
			case	67	: v_valor = fila.c68;break;
			case	68	: v_valor = fila.c69;break;
			case	69	: v_valor = fila.c70;break;
		}
		if	(p_informacion === "E") {
			v_valor = null
		}
		const valor = (v_valor === null) ? v_valor : parseFloat(v_valor)
		return valor
	},
	f_valor(p_informacion,p_valor) {
		let v_valor = p_valor
		if	(p_informacion === "E") {
			v_valor = null
		}
		const valor = (v_valor === null) ? v_valor : parseFloat(v_valor)
		return valor
	},

	async hojaExcelCuentas (datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet("Cuentas")

		const ENCABEZADO1 = [ "Cuentas Contables "+titulo ]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = ["Vigencia "+vigencia ]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = ["Creado "+createdAt+" por "+userName ]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = ["Compañia "+compania ]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [ ]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			"CUENTA",
			"DESCRIPCION",
			"SALDO",
			"FUNCIONAL",
			"PROMEDIO",
			"ALTERNA",
			"PROMEDIO",
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=2;columna<7;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		filas.forEach((fila) => {
			if	(fila.informacion === "E") {
				const v_salto = [ "" ]
				worksheet.addRow(v_salto)
				n_fila++
			}
			const v_fila = [
				fila.cuenta,
				fila.descripcion,
				(fila.saldo_actual === null) ? fila.saldo_actual : parseFloat(fila.saldo_actual),
				(fila.funcional1 === null) ? fila.funcional1 : parseFloat(fila.funcional1),
				(fila.promedio1 === null) ? fila.promedio1 : parseFloat(fila.promedio1),
				(fila.funcional2 === null) ? fila.funcional2 : parseFloat(fila.funcional2),
				(fila.promedio2 === null) ? fila.promedio2 : parseFloat(fila.promedio2),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<7;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna < 2) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: true,
						italic: true,
					}
					worksheet.getCell(v_columna).alignment = {
						horizontal : "left",
					}
				} else {
					worksheet.getCell(v_columna).font = {
						size: 13,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})

		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		downloadLink.download = "Cuentas"+vigencia+".xlsx"
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},

	formato(numero) {
		return  new Intl.NumberFormat('en-US',{ maximumFractionDigits: 0}).format(numero)
	},
	f_muestra(r_origen) {
		if	(r_origen.informacion != "I") {
			return
		}
		this.formCuentas = r_origen
		Balance.getCuentasNiif(this.idCompania,this.vigencia,r_origen.niif)
		.then((result) => {
			this.cuentas = result.data.cuentas
			this.showDialog = true
		})
		.catch(() => {
			this.error = true
		});
		setTimeout(()=>{
			this.t_saldo=0;
			this.t_funcional=0;
			this.t_promedio=0;
			this.t_alterna=0;
			this.t_prom_alterna=0;
			this.cuentas.forEach((cuenta) => {
				this.t_saldo = this.t_saldo+parseFloat(cuenta.saldo_actual)
				this.t_funcional = this.t_funcional+parseFloat(cuenta.funcional1)
				this.t_promedio = this.t_promedio+parseFloat(cuenta.promedio1)
				this.t_alterna = this.t_alterna+parseFloat(cuenta.funcional2)
				this.t_prom_alterna = this.t_prom_alterna+parseFloat(cuenta.promedio2)
			})
			let r_cuenta = {}
			r_cuenta.cuenta = ''
			r_cuenta.descripcion = 'TOTAL CONSULTA'
			r_cuenta.saldo_actual = this.t_saldo
			r_cuenta.funcional1 = this.t_funcional
			r_cuenta.promedio1 = this.t_promedio
			r_cuenta.funcional2 = this.t_alterna
			r_cuenta.promedio2 = this.t_prom_alterna
			this.cuentas.push(r_cuenta)
		},1000)
	},

  },
  mounted() {
    this.isLoading = true;

    Balance.getCompanias(true)
    .then ((result) => {
	this.companias = result.data.companias
	this.dialogCompanias = []
	this.codigoCompanias = "{"
	let primero = true
	result.data.companias.forEach(element => {
		this.dialogCompanias.push(element.id);
		if	(!primero) {
			this.codigoCompanias=this.codigoCompanias+","
		}
		primero=false;
		this.codigoCompanias=this.codigoCompanias+"\"id"+element.id+"\":"+
			"\""+element.codigo+"\""
	})
	this.codigoCompanias=this.codigoCompanias+"}"
	this.codigoCompanias=JSON.parse(this.codigoCompanias)
    })
    .catch(() => {
	this.error = true
    });
    Balance.getFechas()
    .then ((result) => {
	this.fechas = ordenefechas(result.data.fechas)
	this.dialogFechas = []
	result.data.fechas.forEach(element => {
		this.dialogFechas.push(element.fecha);
	})
    })
    .catch(() => {
	this.error = true
    });
    this.isLoading = true;
  },
};
</script>

<style src="../main.css"></style>
