<template>
    <div class="container">
        <md-dialog :md-active.sync="showNewTercerLev" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <h5>{{ tituloNivUno(levelUnoSel) }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <h5>{{ tituloNivDos(levelUnoSel,levelDosSel) }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="number"
                                name="name"
                                v-model="levelThird"
                                :placeholder="labels.levelThird"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="name"
                                v-model="name"
                                :placeholder="labels.name"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="newLevelThird">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>

import PrimerNivel from "../../services/PrimerNivel"
import SegundoNivel from "../../services/SegundoNivel"
import TercerNivel from "../../services/TercerNivel"

export default {
    name:"NewThirdLevel",
    props: ['showNewTercerLev','levelUnoSel','levelDosSel'],

    data: () => ({
        name:"",
        levelThird:"",
        nameSecondLevel:"",
        nameFirstLevel:""
    }),

    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Crear nivel",
                    save : "Guardar",
                    close : "Cerrar",
                    name : "Nombre",
                    levelThird : "Tercer nivel (Números*)"
                }
            }else{
                return {
                    title : "Create level",
                    save : "Save",
                    close : "Close",
                    name : "Name",
                    levelSecond : "Third Lavel (Number*)"
                }
            }
        }
    },

    methods: {
        clicked(){
            this.$emit('clickedTercero',false)
            this.name = ""
            this.levelThird = ""
        },
        newLevelThird(){
		if(this.name == ""){
			this.$toast.error("El campo Nombre debe estar diligenciado")
		}else if(this.levelThird == ""){
			this.$toast.error("El campo Tercer nivel debe estar diligenciado")
		}else{
			let form = {
				'primer_nivel'  : this.levelUnoSel,
				'segundo_nivel'  : this.levelDosSel,
				'tercer_nivel' : this.levelThird,
				'descripcion'   : this.name,
				'idUser'        : this.$store.state.userId,
			}
			TercerNivel.createThirdLevel(form)
			.then((res) => {
				this.$toast.success(res.data.message)
				this.clicked()
			})
			.catch((err) => {
				this.$toast.error(err.message)
				console.log(err)
			})
		}
        },
        tituloNivUno(nivUno){
            PrimerNivel.getFirstLevelById(nivUno)
            .then((res) => {
                // console.log(res.data)
                this.nameFirstLevel = res.data.firstLevelById[0].descripcion
            })
            .catch((error) => {
                console.log(error)
            })
            SegundoNivel.getSecondLevelById(nivUno,this.levelDosSel)
            .then((res) => {
                // console.log(res.data)
                this.nameSecondLevel = res.data.secondLevelById[0].descripcion
            })
            .catch((error) => {
                console.log(error)
            })

            return this.nameFirstLevel
        },
        tituloNivDos(nivUno,nivDos){

            SegundoNivel.getSecondLevelById(nivUno,nivDos)
            .then((res) => {
                // console.log(res.data)
                this.nameSecondLevel = res.data.secondLevelById[0].descripcion
            })
            .catch((error) => {
                console.log(error)
            })

            return this.nameSecondLevel
        },

    }
}

</script>
<style src="../main.css"></style>
