import Api from '@/services/Api'

export default {
    getFechas(){
        return Api().get('getFechas')
    },
    getAjustesConsol(vigencia){
        return Api().get('getAjustesConsol/'+vigencia)
    },
    consulAjusteOrigen(id,vigencia,idCompany){
        return Api().get('getAjustesOrigen/'+id+'/'+vigencia+'/'+idCompany)
    },
    consulAjusteDestino(id,vigencia){
        return Api().get('getAjustesDestino/'+id+'/'+vigencia)
    },
    consulAjusteOrigenById(id){
        return Api().get('getAjustesOrigenById/'+id)
    },
    updateAjusteOrigen(datos){
        return Api().put('updateAjusteOrigen',datos)
    },
    consulAjusteDestinoById(id){
        return Api().get('getAjustesDestinoById/'+id)
    },
    updateAjusteDestino(datos){
        return Api().put('updateAjusteDestino',datos)
    },
    borraAjuste(vigencia,secuencia){
        return Api().post('borraAjuste/'+vigencia+'/'+secuencia)
    },
}
