<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogCrearNiif" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
               <h4> {{ labels.title }} </h4>
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="niif"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="descripcion"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelFirst}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="firstLevSel" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="firstLev in listFirstLevel" :key="firstLev.id" :value="firstLev.primer_nivel">{{ firstLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelSecond}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="secondLevSel" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="secondLev in listSecondLevel" :key="secondLev.id" :value="secondLev.segundo_nivel">{{ secondLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="creaNiif">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
    
    import PlanConsolidacion from '../../services/PlanConsolidacion'
    import PrimerNivel from '../../services/PrimerNivel';
    import SegundoNivel from '../../services/SegundoNivel';

    export default {
        name:"Crear",
        props:['showDialogCrearNiif','vigencia'],

        data: () => ({
            niif:"",
            descripcion:"",
            firstLevSel:"",
            listFirstLevel: [],
            secondLevSel:"",
            listSecondLevel: []
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Crear Niif",
                        niif : "Niif:",
                        description : "Descripción:",
                        select : "Seleccione...",
                        levelFirst : "Primer nivel:",
                        levelSecond : "Segundo nivel:",
                        save : "Guardar",
                        close : "Cerrar",
                    }
                }else{
                    return {
                        title: "Crear Niif",
                        niif : "Niif:",
                        description : "Description:",
                        select : "Select...",
                        levelFirst : "First level:",
                        levelSecond : "Second level:",
                        save : "Save",
                        close : "Close",
                    }
                }
            }
        },
        methods:{
            clearCampos(){
                this.niif = ""
                this.descripcion = ""
                this.firstLevSel = ""
                this.secondLevSel = ""
            },
            clicked(){
                this.$emit('clicked',false)
                this.clearCampos()
            },
            reload(){
                this.$emit('reload',this.vigencia)
            },
            creaNiif(){

console.log(this.firstLevSel)
                if(this.niif == ""){
                    this.$toast.error("El campo Niif debe estar diligenciado")

                }else if(this.descripcion == ""){
                    this.$toast.error("El campo Descripción debe estar diligenciado")
                
                }else if(this.firstLevSel === ""){
                    this.$toast.error("El campo primer nivel debe estar diligenciado")

                }else if(this.secondLevSel === ""){
                    this.$toast.error("El campo segundo nivel debe estar diligenciado")
                
                }else{
                
                    let form = {
                        'niif'          : this.niif,
                        'descripcion'   : this.descripcion,
                        'primerNivel'   : this.firstLevSel,
                        'segundoNivel'   : this.secondLevSel,
                        'vigencia'      : this.vigencia,
                        'idUser'        : this.$store.state.userId
                    }
                    // console.log(form)

                    PlanConsolidacion.createPlanconsolidacion(form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        this.clicked()
                    })
                    .catch((err) => {
                        if(err.response.status == 412){
                            this.$toast.error(err.response.data.message)
                        }
                    })
                    .finally(() => {
                        this.reload()
                    })
                }

            }
        },
        mounted(){
            PrimerNivel.getAllLevelFirst()
            .then((result) => {
                // console.log(result.data.primerNivel)
                this.listFirstLevel = result.data.primerNivel
            })
            .catch((err) => {
                console.log(err)
            })
        },
        watch:{
            firstLevSel(valFirst){
                // console.log(valFirst)
                SegundoNivel.getAllLevelSecondByLevelFirst(valFirst)
                .then((res) => {
                    // console.log(res.data.secondLevByFirstLev)
                    this.listSecondLevel = res.data.secondLevByFirstLev
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    }

</script>
<style src="../main.css"></style>
