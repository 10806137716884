import Api from '@/services/Api'

export default {
    getAllLevelSecond(){
        return Api().get('getAllLevelSecond')
    },
    getAllLevelSecondByLevelFirst(primer_nivel){
        return Api().get('getAllLevelSecondByLevelFirst/'+primer_nivel)
    },
    createSecondLevel(form){
        return Api().post('secondLevel/create',form)
    },
    updateSegundoNivel(form){
        return Api().post('secondLevel/update',form)
    },

    getSecondLevelById(primer_nivel,segundo_nivel){
        return Api().get('getSecondLevelById/'+ primer_nivel+'/'+segundo_nivel)
    },
}
