<template>
<div class="container">
    <md-dialog :md-active.sync="modalCreaCuenta" :md-click-outside-to-close="false">
        <!-- <pre>
            {{ cuentasCreadas }}
        </pre> -->
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content>
            <b-row class="">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.contable}}</h5>
                </b-col>
                <b-col cols="9" lg="9" class="text-left">
                    <select v-model="contable" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="" v-if="showCuenta">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.criterio}}</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <input type="text" v-model="criterioC" v-on:input="f_criterio_cuenta" placeholder="Criterio Cuenta..." class="form-control"><br>
                </b-col>
                <b-col cols="3"></b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <input type="text" v-model="criterioD" v-on:input="f_criterio_descripcion" placeholder="Criterio Descripcion..." class="form-control">
                </b-col>
            </b-row>
            <b-row class="" v-if="showCuenta">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.account}}</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <select multiple v-model="listadoCuentas" class="form-control">
                        <option v-for="cuenta in showCuentas" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }}-{{cuenta.descripcion}}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.actual}}</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <select v-model="actual" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.operacion}}</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <select v-model="operacion" id="" class="form-control">
                        <option value=""> {{ labels.select }} </option>
                        <option value="+"> {{ labels.sumar }} </option>
                        <option value="-"> {{ labels.restar }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="" v-if="showFormulacion">
                <b-col cols="3" class="text-left">
                    <h5>{{labels.formulacion}}</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <input type="radio" value="m" id="" v-model="chk" class="ml-3"> <span>Manual</span>
                    <input type="radio" value="s" id="" v-model="chk" class="ml-3"> <span>Suma</span>
                    <input type="radio" value="n" id="" v-model="chk" class="ml-3"> <span>Niif</span>
                </b-col>
            </b-row>
            <b-row class="" v-if="showSuma">
                <b-col cols="3" class="text-left">
                    <h5>Suma:</h5>
                </b-col>
                <!-- <b-col cols="6" lg="6" class="text-left">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <md-field>
                            <md-select v-model="listLevel" md-dense multiple>
                                <md-option value="fight-club">10</md-option>
                                <md-option value="godfather">11</md-option>
                                <md-option value="godfather-ii">12</md-option>
                            </md-select>
                            </md-field>
                        </div>
                    </div>
                </b-col> -->
                <b-col cols="1" lg="9" class="text-left">
                    <md-field>
                        <!-- <label>Number</label> -->
                        <md-input v-model="sumNiv" type="text"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="" v-if="showNiif">
                <b-col cols="3" class="text-left">
                    <h5>Niif:</h5>
                </b-col>
                <b-col cols="1" lg="9" class="text-left">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <!-- <md-field>
                            <md-select v-model="listNiif" md-dense multiple>
                                <md-option value="fight-club">Fight Club</md-option>
                                <md-option value="godfather">Godfather</md-option>
                                <md-option value="godfather-ii">Godfather II</md-option>
                                <md-option value="godfather-iii">Godfather III</md-option>
                                <md-option value="godfellas">Godfellas</md-option>
                                <md-option value="pulp-fiction">Pulp Fiction</md-option>
                                <md-option value="scarface">Scarface</md-option>
                            </md-select>
                            </md-field> -->
                            <select multiple v-model="arrNiif" class="form-control">
                                <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{ niif.nameNiif }}</option>
                            </select>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardaCuenta">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
</div>
</template>

<script>
import PlanCuentas from "../../services/PlanCuentas"
import Company from "../../services/Company"
import PlanConsolidacion from '../../services/PlanConsolidacion';
import Nivel from '../../services/Nivel';

export default {
    name:"CreaCuenta",
    props:["modalCreaCuenta","nivelSelected","vigComSelected","cuentasCreadas"],
    data:() => ({
        contable: "",
        cuenta: "",
        actual: "",
        operacion: "",
        showCuenta: false,
        showFormulacion: false,
        chk:"",
        showSuma: false,
        showNiif: false,
        listNiif: [],
        listLevel: [],
        selVigencia : "",
        companyId : "",
        listVigencia : [],
        listCompany : [],
        arrNiif : [],
        sumNiv: "",
        criterioC: "",
        criterioD: "",
        listadoCuentas : [],
        listCuentas : [],
        showCuentas : [],
        listCuentasNew : [],
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Crear Detalle",
                    select: "Seleccione...",
                    save : "Guardar",
                    close : "Cerrar",
                    contable : "Contable:",
                    criterio : "Criterio:",
                    yes : "SI",
                    not : "NO",
                    account : "Cuenta:",
                    actual : "Actual:",
                    operacion : "Operación:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formula:",
                    vigencia : "Vigencia:",
                    company : "Compañía:"
                }
            }else{
                return {
                    title : "Create Detail",
                    select: "Select...",
                    save : "Save",
                    close : "Close",
                    contable : "Accountant:",
                    criterio : "Search:",
                    yes : "YES",
                    not : "NO",
                    account : "Account:",
                    actual : "Actual:",
                    operacion : "Operation:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formula:",
                    vigencia : "Validity:",
                    company : "Company:"
                }
            }
        }
    },
    methods:{
        guardaCuenta(){
            // console.log("CREA_CUENTA")
            // console.log(this.listLevel)
            // console.log(this.arrNiif)
            // console.log(this.sumNiv)
            if(this.vigComSelected.vigencia == ""){
                this.$toast.error("El campo vigencia debe estar diligenciado")
            }else if(this.vigComSelected.compania == ""){
                this.$toast.error("El campo compañía debe estar diligenciado")
            }else if(this.contable == ""){
                this.$toast.error("El campo contable debe estar diligenciado")
            }else if(this.actual == ""){
                this.$toast.error("El campo actual debe estar diligenciado")
            }else if(this.operacion == ""){
                this.$toast.error("El campo operación debe estar diligenciado")
            }else{

                let dataInfo = {
                    "vigencia"      : this.vigComSelected.vigencia,
                    "compania"      : this.vigComSelected.compania,
                    "contable"      : this.contable,
                    "cuenta"        : this.contable == 1 ? this.listadoCuentas : "",
                    "actual"        : this.actual,
                    "operacion"     : this.operacion,
                    "nivel"         : this.nivelSelected,
                    "formulacion"   : this.contable == 0 ? this.chk : "",
                    "formSumaNiv"   : this.chk == "s" ? this.sumNiv : "",
                    "formNiif"      : this.chk == "n" ? this.arrNiif : "",
                    'idUser'        : this.$store.state.userId
                }
                // console.log(dataInfo)
                if(this.$route.params.tipo == 'directo'){
                    Nivel.createNewCuentaNivelEfe(dataInfo)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                    })
                    .catch((err) => {
                        console.log(err)
                        if(err.response.status == 412){
                            this.$toast.error(err.response.data.message)
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {                        
                            this.reloadTable()
                        }, 2500);
                    })
                }else{
                    Nivel.createNewCuentaNivelEfeInd(dataInfo)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                    })
                    .catch((err) => {
                        console.log(err)
                        if(err.response.status == 412){
                            this.$toast.error(err.response.data.message)
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {                        
                            this.reloadTable()
                        }, 3000);
                    })
                }
            }
        },
        clicked(){
            this.contable = ""
            this.listadoCuentas = []
            this.actual = ""
            this.operacion = ""
            this.chk = ""
            this.showFormulacion = false
            this.sumNiv = ""
            this.arrNiif = []

            this.$emit('clicked', false);
        },
        reloadTable(){
            this.$emit('reloadTable',this.nivelSelected,this.vigComSelected.vigencia,this.vigComSelected.compania)
        },
        consCuentas(companyId,vigencia){
            let form = {
                'compania' : companyId,
                'vigencia' : vigencia,
                'cuentasList' : this.cuentasCreadas                
            }

            if(this.$route.params.tipo == 'directo'){
                Nivel.cuentasNoCreadas(form)
                .then((result) => {
                    this.listCuentas = result.data.cuentas
                    this.showCuentas = result.data.cuentas
                    // console.log(this.listCuentas)
                })
                .catch((err) => {
                    console.log(err)
                })
            }else{
                Nivel.cuentasNoCreadasInd(form)
                .then((result) => {
                    this.listCuentas = result.data.cuentas
                    this.showCuentas = result.data.cuentas
                    // console.log(this.listCuentas)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
	f_criterio_cuenta() {
		const criterio = "^"+this.criterioC
		if	(this.criterioC.length ==0) {
			this.showCuentas = this.listCuentas
		} else {
			this.showCuentas = this.listCuentas.filter(
			(cuenta) => cuenta.cuenta.match(criterio)>0)
		}
	},
	f_criterio_descripcion() {
		if	(this.criterioD.length == 0) {
			this.showCuentas = this.listCuentas
		} else {
			this.showCuentas = this.listCuentas.filter(
			(cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.criterioD.toUpperCase())>=0)
		}
	}
    },
    mounted(){
        PlanCuentas.getVigenciaPlancuentas()
        .then((result) => {
            // console.log(result.data.getVigencia)
            this.listVigencia = result.data.getVigencia
        })
        .catch((err) => {
            console.log(err)
        })

        Company.getAllCompanies()
        .then((result) => {
            // console.log(result)
            this.listCompany = result.data.companies
        })
        .catch((err) => {
            console.log(err)
        })
    },
    watch:{
        contable(valNew){
            // console.log(valNew)
            if(valNew == 0){
                // console.log("Show Formulacion")
                this.showCuenta = false
                this.showFormulacion = true
                this.listCuentas = []

            }else if(valNew == 1){
                
                this.showCuenta = true
                this.showFormulacion = false
                this.showSuma = false
                this.showNiif = false
                this.chk = ""
                this.listLevel = []            
                this.arrNiif = []
                this.consCuentas(this.vigComSelected.compania,this.vigComSelected.vigencia)            
            }
        },
        chk(valNew){
            // console.log(valNew,valOld)
            if(valNew == 'n'){
                // console.log('niif')
                if(this.vigComSelected.vigencia != ""){

                    PlanConsolidacion.getPlanConsPorCompVige(this.vigComSelected.vigencia)
                    .then((res) => {
                        this.listNiif = res.data.planConsNiif
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                    this.showNiif = true
                    this.showSuma = false
                    this.listLevel = []
                    this.sumNiv = ""
                }else{
                    this.$toast.error("El campo vigencia debe estar diligenciado")
                }

                // this.showNiif = true
                // this.showSuma = false
            }else if(valNew == 's'){
                // console.log('suma')
                this.showSuma = true
                this.showNiif = false
                this.arrNiif = []
                
            }else if(valNew == 'm'){
                // console.log('manual')
                this.showSuma = false
                this.showNiif = false
                this.listLevel = []
                this.arrNiif = []
                this.sumNiv = ""
            }
        },
        /*selVigencia(fechaNew){
            // console.log(fechaNew)
            if(fechaNew != ""){
                PlanConsolidacion.getPlanConsPorCompVige(fechaNew)
                .then((res) => {
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => {
                    console.log(err)
                })

            }else{
                this.listNiif = []
                this.showNiif = false
                this.$toast.error("El campo vigencia debe estar diligenciado")
                this.chk = ""
            }
        }*/
    }
}
</script>

<style>

</style>
