<template>
    <div class="container">
        <md-dialog :md-active.sync="showNewAjuste" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ f_titulo() }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <b-col cols="12" class="text-center"><h6>{{ labels.origen }}</h6></b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.company}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="selCompanyOrig" id="" class="form-control">
                            <option value=""> Seleccione... </option>
                            <option v-for="comp in listCompany" :key="comp.id" :value="comp.id"> {{ comp.acronym+'-'+comp.nombre }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.eliminacion}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="selEliminacion" id="" class="form-control">
                            <option value=""> {{ labels.select }} </option>
                            <!-- <option v-for="comp in listCompany" :key="comp.id" :value="comp.id"> {{ comp.nombre }} </option> -->
                            <option value="1"> {{ labels.intNoContr }} </option>
                            <option value="2"> {{ labels.elimInversion }} </option>
                            <option value="3"> {{ labels.elimSaldoRecip }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{ labels.type }}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <input type="text" v-model="tipoOrig" class="form-control">
                    </b-col>
                </b-row>
                <b-row class="" v-if="showCuentaOrig">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.criterio}}</h5>
                    </b-col>
                    <b-col cols="3" lg="3" class="text-left">
                        <input type="text" v-model="critCuenta" v-on:input="buscaCuenta" placeholder="Criterio Cuenta..." class="form-control">
                    </b-col>
                    <b-col cols="3" lg="3" class="text-left">
                        <input type="text" v-model="critDescripcion" v-on:input="buscaDescripcion" placeholder="Criterio Descripcion..." class="form-control">
                    </b-col>
                </b-row>
                <b-row class="" v-if="showCuentaOrig">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.account}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select multiple v-model="listadoCuentasOrig" class="form-control">
                            <option v-for="cuenta in listCuentasSelMul" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-col class="mt-5" v-if="showDestino">
                    <b-row>
                        <b-col cols="12" class="text-center"><h6>{{ labels.destino }}</h6></b-col>
                    </b-row>
                    <b-row class="">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{labels.company}}</h5>
                        </b-col>
                        <b-col cols="6" lg="6" class="text-left">
                            <select v-model="selCompanyDest" id="" class="form-control">
                                <option value=""> Seleccione... </option>
                                <option v-for="comp in listCompany" :key="comp.id" :value="comp.id"> {{ comp.acronym + '-' + comp.nombre }} </option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row class="">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{ labels.type }}</h5>
                        </b-col>
                        <b-col cols="6" lg="6" class="text-left">
                            <input type="text" v-model="tipoDest" class="form-control">
                        </b-col>
                    </b-row>
                    <b-row class="" v-if="showCuentaDest">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{labels.criterio}}</h5>
                        </b-col>
                        <b-col cols="3" lg="3" class="text-left">
                            <input type="text" v-model="critCuentaDest" v-on:input="buscaCuentaDest" placeholder="Criterio Cuenta..." class="form-control">
                        </b-col>
                        <b-col cols="3" lg="3" class="text-left">
                            <input type="text" v-model="critDescripcionDest" v-on:input="buscaDescripcionDest" placeholder="Criterio Descripcion..." class="form-control">
                        </b-col>
                    </b-row>
                    <b-row class="" v-if="showCuentaDest">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{labels.account}}</h5>
                        </b-col>
                        <b-col cols="6" lg="6" class="text-left">
                            <select multiple v-model="listadoCuentasDest" class="form-control">
                                <option v-for="cuenta in listCuentasSelMulDest" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                            </select>
                        </b-col>
                    </b-row>
                </b-col>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaAjuste">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
  
import Company from '../../services/Company'
import PlanCuentas from '../../services/PlanCuentas'
import BalanceNiif from '../../services/BalanceNiif'

export default {
    name:"NewAjuste",
    props:['showNewAjuste','vigenciaSelect','invocaDesde'],
    data: () => ({
        selCompanyOrig : "",
        listCompany : [],
        showCuentaOrig : false,
        listadoCuentasOrig : [],
        listCuentasOrig : [],
        listCuentasSelMul : [],
        tipoOrig : "",
        valorOrig : "",
        funcionalOrig : "",
        alternaOrig : "",
        selCompanyDest : "",
        showCuentaDest : false,
        listadoCuentasDest : [],
        listCuentasDest : [],
        listCuentasSelMulDest : [],
        tipoDest : "",
        valorDest : "",
        funcionalDest : "",
        alternaDest : "",
        showFuncAltOrig : false,
        showFuncAltDest : false,
        showDestino : false,
        selEliminacion : "",
        critCuenta : "",
        critDescripcion : "",
        critCuentaDest : "",
        critDescripcionDest : "",
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Ajuste",
                    save : "Guardar",
                    close : "Cerrar",
                    select : "Seleccione...",
                    company : "Compañía:",
                    account : "Cuentas:",
                    criterio : "Criterio:",
                    type : "Tipo:",
                    valor : "Valor:",
                    origen : "ORIGEN",
                    funcional : "Funcional:",
                    alterna : "Alterna:",
                    destino : "DESTINO",
                    eliminacion : "Eliminación:",
                    intNoContr : "Interes no controlante",
                    elimInversion : "Eliminación en inversión",
                    elimSaldoRecip : "Eliminación saldo recíproco",

                }
            }else{
                return {
                    title : "New setting",
                    save : "Save",
                    close : "Close",
                    select : "Select...",
                    company : "Company:",
                    account : "Accounts:",
                    criterio : "Criterion:",
                    type : "Type:",
                    valor : "Valor:",
                    origen : "ORIGIN",
                    funcional : "Functional:",
                    alterna : "Alternating:",
                    destino : "DESTINATION",
                    eliminacion : "Elimination:",
                    intNoContr : "Non-controlling interest",
                    elimInversion : "Elimination in investment",
                    elimSaldoRecip : "Reciprocal balance elimination",

                }
            }
        }
    },
    methods:{
        buscaCuenta(){
            const criterio = "^"+this.critCuenta
            if	(this.critCuenta.length ==0) {
                this.listCuentasSelMul = this.listCuentasOrig
            } else {
                this.listCuentasSelMul = this.listCuentasOrig.filter(
                (cuenta) => cuenta.cuenta.match(criterio)>0)
            }
        },
        buscaDescripcion(){
            if	(this.critDescripcion.length == 0) {
                this.listCuentasSelMul = this.listCuentasOrig
            } else {
                this.listCuentasSelMul = this.listCuentasOrig.filter(
                (cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.critDescripcion.toUpperCase())>=0)
            }
        },
        buscaCuentaDest(){
            const criterio = "^"+this.critCuentaDest
            if	(this.critCuentaDest.length ==0) {
                this.listCuentasSelMulDest = this.listCuentasDest
            } else {
                this.listCuentasSelMulDest = this.listCuentasDest.filter(
                (cuenta) => cuenta.cuenta.match(criterio)>0)
            }
        },
        buscaDescripcionDest(){
            if	(this.critDescripcionDest.length == 0) {
                this.listCuentasSelMulDest = this.listCuentasDest
            } else {
                this.listCuentasSelMulDest = this.listCuentasDest.filter(
                (cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.critDescripcionDest.toUpperCase())>=0)
            }
        },
        guardaAjuste(){
            // console.log("guardaAjuste")

            if(this.selEliminacion != 1){

                if(this.selCompanyOrig == ''){
                    this.$toast.error('Diligenciar la compañía origen')
                
                }else if(this.listadoCuentasOrig.length == 0){
                    this.$toast.error('Seleccionar la(s) cuenta(s)')
                
                }else if(this.tipoOrig == ''){
                    this.$toast.error('Diligenciar el tipo')
                
                }else if(this.selCompanyDest == ''){
                    this.$toast.error('Diligenciar la compañía destino')
                
                }else if(this.listadoCuentasDest.length == 0){
                    this.$toast.error('Seleccionar la(s) cuenta(s)')
                
                }else if(this.tipoDest == ''){
                    this.$toast.error('Diligenciar el tipo')
                
                }else{

                    let formOrig = {
                        'vigencia' : this.vigenciaSelect,
                        'idCompany' : this.selCompanyOrig,
                        'tipo' : this.tipoOrig,
                        'cuenta' : this.listadoCuentasOrig,
                        'eliminacion' : this.selEliminacion,
                        'idUser' : this.$store.state.userId
                    }

                    BalanceNiif.ingresaElimAjusteOrig(formOrig)
                    .then((res) =>{
                        console.log(res.data.message)
                    })
                    .catch((err) => {
                        console.log(err)
                    })


                    let formDest = {
                        'vigencia' : this.vigenciaSelect,
                        'idCompany' : this.selCompanyDest,
                        'tipo' : this.tipoDest,
                        'cuenta' : this.listadoCuentasDest,
                        'idUser' : this.$store.state.userId
                    }

                    BalanceNiif.ingresaElimAjusteDest(formDest)
                    .then((res) =>{
                        console.log(res.data.message)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        setTimeout(() =>{
                            this.reloadTbl()
                        },1500)
                    })

                    /*
                    let formOrig = {}
                    let formDest = {}

                    if(this.valorOrig != ''){
                        if(this.funcionalOrig == ''){
                            this.$toast.error('Diligenciar el campo funcional')
                        
                        }else if(this.alternaOrig == ''){
                            this.$toast.error('Diligenciar el campo alterna')
                        
                        }else{

                            formOrig = {
                                'vigencia' : this.vigenciaSelect,
                                'idCompany' : this.selCompanyOrig,
                                'tipo' : this.tipoOrig,
                                'cuenta' : this.listadoCuentasOrig,
                                'valor' : this.valorOrig,
                                'funcional' : this.funcionalOrig,
                                'alterna' : this.alternaOrig,
                                'idUser' : this.$store.state.userId
                            }
                            // console.log(formOrig)

                            BalanceNiif.ingresaElimAjusteOrig(formOrig)
                            .then((res) =>{
                                // console.log(res.data)
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        }
                    
                    }else{

                        formOrig = {
                            'vigencia' : this.vigenciaSelect,
                            'idCompany' : this.selCompanyOrig,
                            'tipo' : this.tipoOrig,
                            'cuenta' : this.listadoCuentasOrig,
                            'valor' : '',
                            'funcional' : '',
                            'alterna' : '',
                            'idUser' : this.$store.state.userId
                        }
                        // console.log(formOrig)

                        BalanceNiif.ingresaElimAjusteOrig(formOrig)
                        .then((res) =>{
                            // console.log(res.data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }

                    if(this.valorDest != ''){
                        if(this.funcionalDest == ''){
                            this.$toast.error('Diligenciar el campo funcional')
                        
                        }else if(this.alternaDest == ''){
                            this.$toast.error('Diligenciar el campo alterna')
                        
                        }else{

                            formDest = {
                                'vigencia' : this.vigenciaSelect,
                                'idCompany' : this.selCompanyDest,
                                'tipo' : this.tipoDest,
                                'cuenta' : this.listadoCuentasDest,
                                'valor' : this.valorDest,
                                'funcional' : this.funcionalDest,
                                'alterna' : this.alternaDest,
                                'idUser' : this.$store.state.userId
                            }
                            // console.log(formDest)

                            BalanceNiif.ingresaElimAjusteDest(formDest)
                            .then((res) =>{
                                // console.log(res.data)
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        }
                    
                    }else{

                        formDest = {
                            'vigencia' : this.vigenciaSelect,
                            'idCompany' : this.selCompanyDest,
                            'tipo' : this.tipoDest,
                            'cuenta' : this.listadoCuentasDest,
                            'valor' : '',
                            'funcional' : '',
                            'alterna' : '',
                            'idUser' : this.$store.state.userId
                        }
                        // console.log(formDest)

                        BalanceNiif.ingresaElimAjusteDest(formDest)
                        .then((res) =>{
                            // console.log(res.data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }*/
                }
            }else{

                if(this.selCompanyOrig == ''){
                    this.$toast.error('Diligenciar la compañía origen')
                
                }else if(this.listadoCuentasOrig.length == 0){
                    this.$toast.error('Seleccionar la(s) cuenta(s)')
                
                }else if(this.tipoOrig == ''){
                    this.$toast.error('Diligenciar el tipo')
                
                }else{

                    let formOrig = {
                        'vigencia' : this.vigenciaSelect,
                        'idCompany' : this.selCompanyOrig,
                        'tipo' : this.tipoOrig,
                        'cuenta' : this.listadoCuentasOrig,
                        'eliminacion' : this.selEliminacion,
                        'idUser' : this.$store.state.userId
                    }

                    BalanceNiif.ingresaElimAjusteOrig(formOrig)
                    .then((res) =>{
                        console.log(res.data.message)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        setTimeout(() =>{
                            this.reloadTbl()
                        },1500)
                    })
                }
            }
        },
        reloadTbl(){

            this.selCompanyOrig = ""
            // this.listCompany = []
            this.showCuentaOrig = false,
            this.listadoCuentasOrig = []
            this.listCuentasOrig = []
            this.tipoOrig = ""
            this.valorOrig = ""
            this.funcionalOrig = ""
            this.alternaOrig = ""
            this.selCompanyDest = ""
            // this.listCompany = []
            this.showCuentaDest = false,
            this.listadoCuentasDest = []
            this.listCuentasDest = []
            this.tipoDest = ""
            this.valorDest = ""
            this.funcionalDest = ""
            this.alternaDest = ""
            this.selEliminacion = ""
            this.showDestino = false

            this.$emit('reloadTbl',this.vigenciaSelect)
        },
        closeModal(){

            this.selCompanyOrig = ""
            // this.listCompany = []
            this.showCuentaOrig = false,
            this.listadoCuentasOrig = []
            this.listCuentasOrig = []
            this.tipoOrig = ""
            this.valorOrig = ""
            this.funcionalOrig = ""
            this.alternaOrig = ""
            this.selCompanyDest = ""
            // this.listCompany = []
            this.showCuentaDest = false,
            this.listadoCuentasDest = []
            this.listCuentasDest = []
            this.tipoDest = ""
            this.valorDest = ""
            this.funcionalDest = ""
            this.alternaDest = ""

            this.$emit('closeModal', false);
        },

	f_titulo() {
		if	(this.$store.state._language == "es-ES"){
			return "Ajustes de consolidación"
		} else {
			return	"Consolidation adjustments"
		}
	},
    },
    mounted(){
        Company.getAllCompanies()
        .then((result) => {
            this.listCompany = result.data.companies
        })
        .catch((error) => {
            console.log(error)
        })
    },
    watch:{
        selCompanyOrig(idComp){

            if(idComp != ""){

                PlanCuentas.planCuentPorCompaVig(idComp,this.vigenciaSelect)
                .then((res) => {
                    // console.log(res.data.planCuentas)
                    this.listCuentasOrig = res.data.planCuentas
                    this.listCuentasSelMul = res.data.planCuentas
                    this.showCuentaOrig = true
                })
                .catch((err) =>{
                    console.log(err)
                })

            }else{
                this.showCuentaOrig = false
                this.listCuentasOrig = ""
            }
        },
        selCompanyDest(idComp){

            if(idComp != ""){

                PlanCuentas.planCuentPorCompaVig(idComp,this.vigenciaSelect)
                .then((res) => {
                    // console.log(res.data.planCuentas)
                    this.listCuentasDest = res.data.planCuentas
                    this.listCuentasSelMulDest = res.data.planCuentas
                    this.showCuentaDest = true
                })
                .catch((err) =>{
                    console.log(err)
                })

            }else{
                this.showCuentaDest = false
                this.listCuentasDest = ""
            }
        },
        valorOrig(newVal){
            if(newVal != ''){
                this.showFuncAltOrig = true
            }else{
                this.showFuncAltOrig = false
            }
        },
        valorDest(valNew){
            if(valNew != ''){
                this.showFuncAltDest = true
            }else{
                this.showFuncAltDest = false
            } 
        },
        selEliminacion(valElim){
            // console.log(valElim)
            if(valElim != 1){
                this.showDestino = true
            }else{
                this.showDestino = false
            }
        }
    }
}
</script>
