<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogEditNiif" :md-click-outside-to-close="false">
            <!-- <pre>
                {{ Item }}
            </pre> -->
            <md-dialog-title class="text-center">
               <h4> {{ labels.title }} </h4>
            </md-dialog-title>
            <md-dialog-content>
                <!-- <b-row class="mb-3">
                    <b-col md="5" lg="7" class="mx-auto">
                        <h5>{{ labels.niif }} {{ Item.niif }}</h5>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col md="5" lg="7" class="mx-auto">
                        <h5>{{ labels.description }}</h5>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col md="5" lg="7" class="mx-auto">
                        <md-field>
                            <md-input
                                type="text"
                                name="name"
                                v-model="Item.descripcion"                                
                            />
                        </md-field>
                    </b-col>
                </b-row> -->
                <b-row class="">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h4> {{ Item.niif }} </h4>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="Item.descripcion"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelFirst}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="Item.primer_nivel" @change="onChange($event)" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="firstLev in listFirstLevel" :key="firstLev.id" :value="firstLev.primer_nivel">{{ firstLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelSecond}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="Item.segundo_nivel" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="secondLev in listSecondLevel" :key="secondLev.id" :value="secondLev.segundo_nivel">{{ secondLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="editarNiif">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
    
    import PlanConsolidacion from "../../services/PlanConsolidacion"
    import PrimerNivel from '../../services/PrimerNivel';
    import SegundoNivel from '../../services/SegundoNivel'

    export default {
        name:"EditaNiif",
        props:['Item','showDialogEditNiif'],

        data: () => ({
            descripcion: "",
            idNiif: "",
            listFirstLevel : [],
            listSecondLevel : [],
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Editar Niif",
                        niif : "Niif:",
                        select : "Seleccione...",
                        levelFirst : "Primer nivel:",
                        levelSecond : "Segundo nivel:",
                        save : "Guardar",
                        close : "Cerrar",
                        description : "Descripción:"
                    }
                }else{
                    return {
                        title: "Edit Niif",
                        niif : "Niif:",
                        select : "Select...",
                        levelFirst : "First level:",
                        levelSecond : "Second level:",
                        save : "Save",
                        close : "Close",
                        description : "Description:"
                    }
                }
            }
        },

        methods: {
            clicked(){
                this.$emit('clicked', false);
            },
            reload(){
                this.$emit('reload',this.Item.vigencia)
            },
            editarNiif(){

                if(this.Item.descripcion == ""){
                    this.$toast.error("Diliganciar el campo Descripción")
                }else{
                    let form = {
                        'idNiif'        : this.Item.id,
                        'descripcion'   : this.Item.descripcion,
                        'primer_nivel'   : this.Item.primer_nivel,
                        'segundo_nivel'   : this.Item.segundo_nivel
                    }
                    // console.log(form)

                    PlanConsolidacion.updatePlanconsolidacion(form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        this.clicked()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.reload()
                    })
                }                
            },
            onChange(event){
                // console.log(event.target.value,"VVVV")
                let value = event.target.value

                this.Item.segundo_nivel = ""
                SegundoNivel.getAllLevelSecondByLevelFirst(value)
                .then((res) => {
                    // console.log(res)
                    this.listSecondLevel = res.data.secondLevByFirstLev
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        mounted(){
            PrimerNivel.getAllLevelFirst()
            .then((res) => {
                // console.log(res.data.primerNivel)
                this.listFirstLevel = res.data.primerNivel
            })
            .catch((err) => {
                console.log(err)
            })

            SegundoNivel.getAllLevelSecond()
            .then((res) => {
                // console.log(res.data.segundoNivel)
                this.listSecondLevel = res.data.segundoNivel
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
</script>
<style src="../main.css"></style>