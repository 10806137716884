<template>
  <div class="container">
    <div class="row col-md-12 justify-content-center">
        <h2>{{f_titulo()}}</h2>
    </div>
    <div class="row col-md-12 mt-4">
        <div class="col-md-6 d-flex justify-content-end">
            <h5>{{ labels.vigencia }}</h5>
        </div>
        <div class="col-md-6 d-flex justify-content-start">
            <h5><select id="selVigencia" name="" v-model="selVigencia">
                <option value="">Seleccione...</option>
                <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
            </select></h5>
        </div>
    </div>
    <div class="col-md-12 mt-3">
        <div class="d-flex justify-content-center">
            <b-button @click="consultAjuste()" id="consult-btn">{{ labels.consultar }}</b-button>
        </div>
    </div>
    <div class="col-md-12 mt-5">
        <div v-if="isEmpty">
            <b-col md="2" class="mx-auto">
                <div class="success-svg mb-4" :class="{ active: isEmpty }">
                    <img src="../../assets/imgs/file-2.svg" alt="warning" />
                </div>
            </b-col>
            <b-col md="12" class="justify-content-around">
                <span id="message" v-if="isEmpty">{{labels.empty}}</span>
            </b-col>
            <b-col md="12" class="mt-3">
                <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="adicionAjuste()">
                    <md-icon>add</md-icon>
                </md-button>
            </b-col>
        </div>
        <div v-if="showTblAjuste">
            <div class="row col-md-12 d-flex justify-content-end">
                <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="adicionAjuste()">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
            <md-table v-model="ajustes" md-card md-fixed-header>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell :md-label="labels.secuencia" class="text-center">{{item.secuencia}}</md-table-cell>
                    <md-table-cell :md-label="labels.tipo_ajuste" class="text-center">{{ tipoAjuste(item.tipo_ajuste) }}</md-table-cell>
                    <md-table-cell :md-label="labels.origen_nombre" class="text-left" >{{ item.origen_nombre }}</md-table-cell>
                    <md-table-cell :md-label="labels.tipo_origen" class="text-left" >{{ item.tipo_origen }}</md-table-cell>
                    <md-table-cell :md-label="labels.destino_nombre" class="text-left" >{{ item.destino_nombre }}</md-table-cell>
                    <md-table-cell :md-label="labels.tipo_destino" class="text-left" >{{ item.tipo_destino }}</md-table-cell>
                    <md-table-cell :md-label="labels.information" class="text-center" >
                        <md-button @click="infoAjuste(item.secuencia,item.origen)" class = "md-raised md-primary btn-info"><md-icon>info</md-icon></md-button>
                    </md-table-cell>
                    <md-table-cell :md-label="labels.eliminar" class="text-center" >
                        <md-button @click="borraAjuste(item.secuencia)" style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary"><md-icon>delete</md-icon></md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
    <NewAjuste :showNewAjuste="showNewAjuste" :vigenciaSelect="selVigencia" @reloadTbl="refrescaTbl" @closeModal="cierraModal" />
    <div class="row col-md-12">
        <div class="col-md-12 mt-4" v-if="showInfoAjuste">
            <AjustesOrigen :ajustOrigen="ajusteOrig"/>
        </div>
        <div class="col-md-12 mt-4" v-if="showInfoAjuste">
            <AjustesDestino :ajustDestino="ajusteDesti" />
        </div>
    </div>
        
  </div>
</template>

<script>

import PlanCuentas from '../../services/PlanCuentas'
import Ajustes from '../../services/AjustesConsol'
import NewAjuste from './NewAjuste.vue'
import AjustesOrigen from './AjustesOrigen.vue'
import AjustesConsol from '../../services/AjustesConsol'
import AjustesDestino from './AjustesDestino.vue'

export default {
    name:"AjustesConsol",
    components:{
        NewAjuste,
        AjustesOrigen,
        AjustesDestino
    },
    data:() => ({
        isEmpty : false,
        showTblAjuste : false,
        selVigencia : "",
        listVigencia : [],
        ajustes : [],
        ajusteOrig : [],
        ajusteDesti : [],
        showNewAjuste: false,
        showInfoAjuste: false,
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    title : "Ajustes de consolidación",
                    titler : "Reclasificacion de Saldos",
                    consultar : "CONSULTAR",
                    vigencia : "Vigencia:",
                    empty : "No hay registros.",
                    titleTbl : "Ajustes",
                    secuencia : "Secuencia",
                    tipo_ajuste : "Tipo ajuste",
                    origen_nombre : "Empresa",
                    tipo_origen : "Tipo",
                    destino_nombre : "Empresa",
                    tipo_destino : "Tipo",
                    information : "Información",
                    eliminar : "Eliminar"
                }  
            }else{
                return {
                    title : "Consolidation adjustments",
                    titlear : "Balance Reeclassification",
                    consultar : "CONSULT",
                    vigencia : "Validity:",
                    empty : "Thre is not row",
                    titleTbl : "Adjustments",
                    secuencia : "Sequence",
                    tipo_ajuste : "Setting type",
                    origen_nombre : "Company",
                    tipo_origen : "Type",
                    destino_nombre : "Company",
                    tipo_destino : "Type",
                    information : "Information",
                    eliminar : "Delete"
                }                
            }
        }
    },
    methods:{
        consultAjuste(){
        Ajustes.getAjustesConsol(this.selVigencia)
	.then((result) => {
		this.ajustes = result.data.ajustes;
		if (this.ajustes.length != 0) {
			this.isEmpty = false;
			this.showTblAjuste = true;
			// this.generado=true
		}else{
			this.isEmpty = true;
			this.showTblAjuste = false;
			this.showInfoAjuste = false
		}
	})
	.catch(() => {
		this.error = true;
	});
        },
        tipoAjuste(tipoAjus){
            if(tipoAjus == 1){
                return 'Interes no controlante'
            }else if(tipoAjus == 2){
                return 'Eliminación en inversión'
            }else if(tipoAjus == 3){
                return 'Eliminación saldo recíproco'
            }
        },
        adicionAjuste(){
            this.showNewAjuste = true
        },
        refrescaTbl(val){
		Ajustes.getAjustesConsol(val)
		.then((result) => {
			this.ajustes = result.data.ajustes
			this.$toast.success('Ajustes actualizados exitosamente')
			this.showNewAjuste = false
			this.showInfoAjuste = false
		})
		.catch(() => {
		this.error = true;
		});
        },
        cierraModal(val){
            this.showNewAjuste = val
        },
	f_titulo() {
		if	(this.$store.state._language == "es-ES"){
			return "Ajustes de consolidación"
		} else {
			return	"Consolidation adjustments"
		}
	},
        async infoAjuste(id,idCompOrig){
            // console.log(id)
            let ajusteOrig = await AjustesConsol.consulAjusteOrigen(id,this.selVigencia,idCompOrig)
            this.ajusteOrig = ajusteOrig.data.ajustOrig

            let ajusteDest = await AjustesConsol.consulAjusteDestino(id,this.selVigencia)
            this.ajusteDesti = ajusteDest.data.ajustDest
            
            this.showInfoAjuste = true
        },
        async borraAjuste(secuencia){
            // console.log(secuencia)
		Ajustes.borraAjuste(this.selVigencia,secuencia)
		.then((res) =>{
			console.log(res.data.message)
			this.refrescaTbl(this.selVigencia)
		})
		.catch((err) => {
			console.log(err)
		})
        },
    },
    mounted(){
        PlanCuentas.getVigenciaPlancuentas()
        .then((result) => {
		this.listVigencia = result.data.getVigencia
		this.showCuentas = this.listVigencia
        })
        .catch((err) => {
            console.log(err)
        })
    }
}
</script>

<style>

</style>
