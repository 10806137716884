<template>
    <div class="container">
        <div class="col-md-12">
            <h2>{{ labels.title }}</h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-3 h5">
                <label for="selVigencia" class=""> {{ labels.validity }}</label>
                <select id="selVigencia" name="" v-model="selVigencia">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                </select>
            </div>
            <!-- <div class="col-md-3">
                <b-button @click="consultaNiif()" id="consult-btn">{{ labels.consultar }}</b-button>
            </div> -->
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-md-12">
                <b-button @click="consultaNiif()" id="consult-btn">{{ labels.consultar }}</b-button>
            </div>
        </div>
        <!-- <div class="row justify-content-center mt-2" v-if="showBtnCrear">
            <div class="col-md-12">
                <b-button @click="crearNiif()" id="consult-btn">{{labels.create}}</b-button>
            </div>
        </div> -->
        <div v-if="listPlanConsoli.length != 0">
            <div class="row">
                <div class="col-md-3 justify-content-left">
                    <md-field md-inline>
                        <label>{{labels.search}}</label>
                        <md-input v-model="searchNiif" @input="searchOnTableNiif"></md-input>
                    </md-field>
                </div>
                <div class="col-md-9 d-flex justify-content-end">
                    <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="crearNiif()">
                        <md-icon>add</md-icon>
                    </md-button>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 mt-4">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <!-- <th scope="col">{{labels.validity}}</th> -->
                                <th scope="col">{{labels.niif}}</th>
                                <th scope="col">{{labels.description}}</th>
                                <th scope="col">{{labels.levelFirst}}</th>
                                <th scope="col">{{labels.levelSecond}}</th>
                                <th scope="col">{{labels.edit}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="planCons in datosPaginados" :key="planCons.id">
                                <!-- <td>{{ planCons.vigencia }}</td> -->
                                <td>{{ planCons.niif }}</td>
                                <td class="text-left">{{ planCons.nameNiif }}</td>
                                <td class="text-left">{{ planCons.namePrimerNivel }}</td>
                                <td class="text-left">{{ planCons.nameSegundoNivel }}</td>
                                <td>
                                    <md-button @click="editaNiif(planCons.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">{{ labels.previous }}</a></li>
                            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">{{labels.next}}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div v-else class="mt-4 h4"> No hay datos</div>
        <div v-if="!isLoading"></div>
        <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
        </div>
        <Edit :showDialogEditNiif="showDialogEditNiif" @clicked="closeModal" :Item="itemSelected" @reload="refrescaTabla"></Edit>
        <Crear :showDialogCrearNiif="showDialogCrearNiif" :vigencia="vigenciaSelected" @clicked="closeModal" @reload="refrescaTabla"/>
    </div>
</template>
<script>
    import PlanConsolidacion from '../../services/PlanConsolidacion';
    // import Company from '../../services/Company';
    import Crear from './Crear.vue';
    import Edit from './Edit.vue';

    const toLower = (text) => {
        return text.toString().toLowerCase();
    };

    const searchByNiif = (items, term) => {
        if (term) {
            return items.filter((item) =>
                toLower(item.nameNiif).includes(toLower(term))
            );
        }
        return items;
    };

    export default {
        name: "Niif",
        data: () => ({
            companyId : "",
            selVigencia : "",
            searchNiif : null,
            listPlanConsoli : [],
            elementosPorPagina:10,
            datosPaginados: [],
            paginaActual: 1,
            showDialogCrearNiif: false,
            showDialogEditNiif: false,
            itemSelected : {},
            listVigencia: [],
            isLoading: false,
            showBtnCrear: false,
            vigenciaSelected: ""
            // listCompany: []
        }),
        components: {
            Edit,
            Crear
        },
        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "NIIF",
                        select: "Seleccione...",
                        search: "Buscar",
                        create: "Crear",
                        niif : "Niif",
                        company : "Comapañía",
                        validity : "Vigencia: ",
                        description : "Descripción",
                        levelFirst : "Primer Nivel",
                        levelSecond : "Segundo Nivel",
                        consultar : "Consultar",
                        edit : "Editar",
                        previous: "Ant.",
                        next: "Sig.",
                    }
                }else{
                    return {
                        title: "NIIF",
                        select: "Select...",
                        search: "Search",
                        create: "Create",
                        niif : "Niif",
                        company : "Company",
                        validity : "Validity: ",
                        description : "Description",
                        levelFirst : "Level first",
                        levelSecond : "Level second",
                        consultar : "Consult",
                        edit : "Edit",
                        previous: "Previous",
                        next: "Next",
                    }
                }
            }
        },
        methods: {
            consultaNiif(){
                // console.log(this.selVigencia)
                this.isLoading = true;
                this.showBtnCrear = true;

                PlanConsolidacion.getPlanConsPorCompVige(this.selVigencia)
                .then((res) => {
                    this.listPlanConsoli = res.data.planConsNiif
                    this.getDataPagina(1);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false;
                })
            },
            crearNiif(){
                this.showDialogCrearNiif = true
                this.vigenciaSelected = this.selVigencia
            },
            editaNiif(id){
                // console.log(id)
                this.showDialogEditNiif = true
                PlanConsolidacion.getConsolidationPlanById(id)
                .then((res) => {
                    // console.log(res)
                    this.itemSelected = res.data.niif[0]
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            closeModal(value){
                this.showDialogEditNiif = value
                this.showDialogCrearNiif = value
            },
            refrescaTabla(vigencia){
                // console.log(vigencia,"EEE")
                this.isLoading = true
                PlanConsolidacion.getPlanConsPorCompVige(vigencia)
                .then((res) => {
                    this.listPlanConsoli = res.data.planConsNiif
                    this.getDataPagina(1);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false;
                })
            },
            searchOnTableNiif(){
                this.datosPaginados = searchByNiif(this.listPlanConsoli, this.searchNiif);
                if(this.searchNiif == ''){
                    this.getDataPagina(1)
                }
            },
            totalPaginas(){
                return Math.ceil(this.listPlanConsoli.length / this.elementosPorPagina)
            },
            getDataPagina(noPagina){
                this.datosPaginados = [];
                this.paginaActual = noPagina;
                let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
                let fin = (noPagina * this.elementosPorPagina);

                this.datosPaginados = this.listPlanConsoli.slice(ini,fin);
            },
            getPreviousPage(){
                if(this.paginaActual > 1){
                    this.paginaActual--;
                }
                this.getDataPagina(this.paginaActual);
            },
            getNextPage(){
                if(this.paginaActual < this.totalPaginas()){
                    this.paginaActual++;
                }
                this.getDataPagina(this.paginaActual);
            },
                isActive(noPagina){
                return noPagina == this.paginaActual ? 'active':'';
            },
        },
        mounted(){
            this.isLoading = true;

            PlanConsolidacion.getVigenciaPlanCons()
            .then((res) => {
                this.listVigencia = res.data.getVigencia
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false;
            })

        }
    }
</script>
<style src="../main.css"></style>
