import Api from '@/services/Api'

export default {
    getConsolidationPlanById(id){
        return Api().get('planConsolidacionById/'+ id)
    },
    updatePlanconsolidacion(form){
        return Api().put('planConsolidacion/update',form)
    },
    getConsolidPlanBySecondId(id){
        return Api().get('getConsolidPlanBySecondId/'+ id)
    },
    getVigenciaPlanCons(){
        return Api().get('planConsolidacion/getVigenciaPlanCons')
    },
    getPlanConsPorCompVige(vigencia){
        return Api().get('planConsolidacion/getPlanConsPorCompVige/'+ vigencia)
    },
    createPlanconsolidacion(form){
        return Api().post('planConsolidacion/create',form)
    }
}
