<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="number"
                                name="name"
                                v-model="levelFirst"
                                :placeholder="labels.levelFirst"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="name"
                                v-model="name"
                                :placeholder="labels.name"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="newLevelFirst">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>

    import PrimerNivel from '../../services/PrimerNivel'
    export default {
        name:"NewFirstLevel",
        props: ['showDialog'],

        data: () => ({
            name:"",
            levelFirst:"",
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title : "Crear nivel",
                        save : "Guardar",
                        close : "Cerrar",
                        name : "Nombre",
                        levelFirst : "Primer nivel (Números*)"
                    }
                }else{
                    return {
                        title : "Create level",
                        save : "Save",
                        close : "Close",
                        name : "Name",
                        levelFirst : "Level first (Number*)"

                    }
                }
            }
        },
        methods:{
            clicked(){
                this.$emit('clicked',false)
                this.name = ""
                this.levelFirst = ""
            },
            newLevelFirst(){
                // console.log(this.$store.state.userId)
                if(this.name == ""){
                    this.$toast.error("El campo Nombre debe estar diligenciado")

                }else if(this.levelFirst == ""){
                    this.$toast.error("El campo Primer nivel debe estar diligenciado")
                }
                else{

                    let form = {
                        'primer_nivel'  : this.levelFirst,
                        'descripcion'   : this.name,
                        'idUser'        : this.$store.state.userId,
                    }

                    PrimerNivel.createLevelFirst(form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        location.reload()
                    })
                    .catch((err) => {
                        this.$toast.error(err.message)
                        // console.log(err)
                    })
                    
                }
            }
        }
    }
</script>
<style src="../main.css"></style>