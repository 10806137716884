<template>
  <div>
    <md-dialog :md-active.sync="modalEditaCuenta" :md-click-outside-to-close="false">
        <!-- <pre>
            {{ dataDetallCuent }}
        </pre> -->
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.contable}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <h6>{{ dataDetallCuent[0].contable == 1 ? 'SI' : 'NO' }}</h6>
                </b-col>
            </b-row>
            <b-row class="" v-if="this.dataDetallCuent[0].contable == 1 ? true : showCuenta">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.account}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <h6>{{ dataDetallCuent[0].cuenta }}</h6>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.actual}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="dataDetallCuent[0].actual" id="" class="form-control">
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.operacion}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="dataDetallCuent[0].operacion" id="" class="form-control">
                        <!-- <option value=""> {{ labels.select }} </option> -->
                        <option value="+"> {{ labels.sumar }} </option>
                        <option value="-"> {{ labels.restar }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="" v-if="this.dataDetallCuent[0].contable == 0 ? true : showFormulacion">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.formulacion}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <input type="radio" value="m" id="" v-model="chk" class="ml-3"> <span>Manual</span>
                    <input type="radio" value="s" id="" v-model="chk" class="ml-3"> <span>Suma</span>
                    <input type="radio" value="n" id="" v-model="chk" class="ml-3"> <span>Niif</span>
                </b-col>
            </b-row>
            <b-row class="" v-if="showSuma">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Suma:</h5>
                </b-col>
                <b-col cols="1" lg="6" class="text-left">
                    <md-field>
                        <md-input v-model="dataDetallCuent[0].formulacion" type="text"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="" v-if="showNiif">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>Niif:</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <select multiple v-model="arrNiif">
                                <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{ niif.nameNiif }}</option>
                            </select>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardaCuenta">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>

import PlanConsolidacion from '../../services/PlanConsolidacion';
import Nivel from '../../services/Nivel'

export default {
    name:"EditaCuenta",
    props:["modalEditaCuenta","dataDetallCuent"],
    data:() => ({
        showCuenta : false,
        showFormulacion : false,
        chk : '',
        showNiif : false,
        showSuma : false,
        sumNiv : "",
        listNiif : [],
        arrNiif : [],
        nivelSelected : {}
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Edita cuenta",
                    select: "Seleccione...",
                    save : "Guardar",
                    close : "Cerrar",
                    contable : "Contable:",
                    yes : "SI",
                    not : "NO",
                    account : "Cuenta:",
                    actual : "Actual:",
                    operacion : "Operación:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulación:",
                    vigencia : "Vigencia:",
                    company : "Compañía:"
                }
            }else{
                return {
                    title : "Edit account",
                    select: "Select...",
                    save : "Save",
                    close : "Close",
                    contable : "Accountant:",
                    yes : "YES",
                    not : "NO",
                    account : "Account:",
                    actual : "Actual:",
                    operacion : "Operation:",
                    sumar : "+",
                    restar : "-",
                    formulacion : "Formulation:",
                    vigencia : "Validity:",
                    company : "Company:"
                }
            }
        }
    },
    methods:{
        guardaCuenta(){
            // console.log(this.dataDetallCuent)
            this.dataDetallCuent[0].chkSel = this.chk
            if(this.chk == 'm'){
                this.dataDetallCuent[0].formulacion = "MANUAL"
            }else if(this.chk == 'n'){
                this.dataDetallCuent[0].formulacion = this.arrNiif
            
            }else if(this.chk == 's'){
                this.dataDetallCuent[0].formulacion
            }
            // console.log(this.dataDetallCuent[0])

            if(this.$route.params.tipo == 'directo'){
                this.dataDetallCuent[0].idUser = this.$store.state.userId
                
                Nivel.updateCuentaNivelEfe(this.dataDetallCuent[0])
                .then((res) => {
                    // console.log(res.status)
                    this.$toast.success(res.data.message)
                    this.clicked()
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setTimeout(() => {                        
                        this.reloadTable()
                    }, 2000);
                })
            }else{
                
                this.dataDetallCuent[0].idUser = this.$store.state.userId

                Nivel.updateCuentaNivelEfeInd(this.dataDetallCuent[0])
                .then((res) => {
                    // console.log(res.status)
                    this.$toast.success(res.data.message)
                    this.clicked()
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setTimeout(() => {                        
                        this.reloadTable()
                    }, 2000);
                })
            }
        },
        clicked(){
            this.$emit('clicked', false);
        },
        reloadTable(){
            this.nivelSelected = {
                'nivelUno'      : this.dataDetallCuent[0].nivel_1,
                'nivelDos'      : this.dataDetallCuent[0].nivel_2,
                'nivelTres'     : this.dataDetallCuent[0].nivel_3,
                'nivelCuatro'   : this.dataDetallCuent[0].nivel_4
            }
            this.$emit('reloadTable',this.nivelSelected,this.dataDetallCuent[0].vigencia,this.dataDetallCuent[0].id_compania)
        }
    },
    watch:{
        dataDetallCuent(valNew){
            // console.log(valNew)
            if(valNew[0].formulacion.slice(0,1) == "M"){
                this.chk = 'm'
                this.showNiif = false
                this.showSuma = false
            
            }else if(valNew[0].formulacion.slice(0,1) == ""){
                this.showNiif = false
                this.showSuma = false
            }
            else if(valNew[0].formulacion.slice(0,1) == "N" && valNew[0].contable == 0){
                this.chk = 'n'
                this.showNiif = true
                this.showSuma = false

                PlanConsolidacion.getPlanConsPorCompVige(valNew[0].vigencia)
                .then((res) => {
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => {
                    console.log(err)
                })
            
            }else if(valNew[0].formulacion.slice(0,1) == "S" && valNew[0].contable == 0){
                this.chk = 's'
                this.showSuma = true
                this.showNiif = false
            }
        
        },
        chk(valNew){
            // console.log(valNew)
            if(valNew == "m"){
                // this.dataDetallCuent[0].formulacion = ""
                this.showNiif = false
                this.showSuma = false
                this.dataDetallCuent[0].chkSel = valNew

            }else if(valNew == "n"){
                this.dataDetallCuent[0].formulacion = ""
                this.showNiif = true
                this.showSuma = false
                this.dataDetallCuent[0].chkSel = valNew

                PlanConsolidacion.getPlanConsPorCompVige(this.dataDetallCuent[0].vigencia)
                .then((res) => {
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => {
                    console.log(err)
                })

            }else if(valNew == "s"){
                // this.dataDetallCuent[0].formulacion = ""
                this.showSuma = true
                this.showNiif = false
                this.dataDetallCuent[0].chkSel = valNew
            }
        }
    }
}
</script>

<style>

</style>
